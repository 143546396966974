// Environment Configuration object to store details of server urls
// for dev server use : dev
// for production server use : prod

let env = 'dev';
let tld = "in"
let domain = "asheet"

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
if (parts.length >= 3) {
  env = parts[0];
  domain = parts[1];
  tld = parts[2];
}

const ASApiUrl = `https://${env}-api.${domain}.${tld}/api/v1`;
const TokenApiUrl = `https://${env}-ts.${domain}.${tld}`;
const Environment = {

  getUrl() {
    return ASApiUrl;
  },
  getTokenAPIUrl() {
    return TokenApiUrl;
  },
};

export { Environment };

