export const ASGroups = ({ flag, className, size }) => {
    return (
        <>
            <svg version="1.0"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width={size ? size : "28"}
                height={size ? size : "25.455"}
                viewBox="0 0 35 35"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                    fill="#16365F" stroke="none">
                    <path d="M22 288 c-18 -18 -14 -46 7 -58 63 -33 152 15 109 58 -16 16 -100 16 -116 0z"/>
                    <path d="M186 204 c-35 -35 -11 -64 54 -64 49 0 70 13 70 42 0 36 -93 53 -124 22z"/>
                    <path d="M70 142 c0 -40 5 -73 12 -80 15 -15 82 -16 73 -2 -3 6 -19 10 -36 10 -28 0 -29 1 -29 50 l0 50 36 0 c21 0 33 4 29 10 -3 6 -19 10 -36 10 -16 0 -29 5 -29 10 0 6 -4 10 -10 10 -6 0 -10 -29 -10 -68z"/>
                    <path d="M186 85 c-37 -37 -8 -68 60 -63 54 3 77 26 58 55 -15 25 -96 30 -118 8z"/>
                </g>
            </svg>
        </>
    );
};

