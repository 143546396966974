import { Autocomplete, Box, Button, Chip, InputLabel, MenuItem, Modal, Stack, Typography, styled } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import '../plugin-parameters/plugin-parameters';
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { useMemo } from "react";
import { autocompleteClasses } from '@mui/material/Autocomplete';
import themeColor from "../../../styles/_exports.module.scss";


export const StyledParamAutocompletePopper = styled('div')(({ theme }) => ({

    [`& .${autocompleteClasses.listbox}`]: {
      fontSize: '13px !important',
      paddingTop: '0px !important',
  
      [`& .${autocompleteClasses.option}`]: {
        '&[aria-selected="true"]': {
          backgroundColor: `${themeColor.primaryColor} !important`,
          color: `white !important`
        },
  
        '&[data-focus="true"]': {
          backgroundColor: `${themeColor.primaryColor} !important`,
          color: `white !important`
        },
  
        '&:hover': {
          backgroundColor: `${themeColor.avatarBackground}`,
          color: `${themeColor.secondaryColor}`
        },
      },
    },
  
    [`&.${autocompleteClasses.popper}`]: {
      marginTop: '-0.3em',
    },
  }));

export const Parameters = (props) => {

    const { parameter, index, dateObject, complexTypeValue, handleOnInputChange, handleMultiSelectedFields, multiSelectedFields, optionValues, state} = props;

    const memoizedMultiSelectDropdownInputLabelProps = useMemo(() => ({
        shrink: parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList
        ? (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.filter(option => option.isSelected)?.length > 0 ? true : undefined)
        : (multiSelectedFields?.length > 0 ? true : undefined)
    }), [parameter?.sysDataTypeId, state?.dynamicListOptions, complexTypeValue?.DataCollectionUId, multiSelectedFields]);

    const memoizedDynamicListValue = useMemo(() => {
        return state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.length > 0
            ? (state.dynamicListOptions[complexTypeValue?.parameterName].filter(option => option.isSelected)[0]?.label || "")
            : "";
    }, [state?.dynamicListOptions, complexTypeValue?.DataCollectionUId]);

    return (
        <div className="filter-box ">
        { parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable && parameter.isMultiSelectList ?
            <Autocomplete
                multiple
                id="tags-filled"
                className="text-field1 label-text-field"
                label={parameter?.displayName} 
                options={
                    parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList 
                    ? (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.length > 0 
                        ? state.dynamicListOptions[complexTypeValue?.parameterName] 
                        : []) 
                    : 
                    optionValues
                }
                getOptionLabel={(option) => option.label}
                value={
                    parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList 
                    ? (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.length > 0 
                        ? state.dynamicListOptions[complexTypeValue?.parameterName].filter(option => option.isSelected)
                        : []) 
                    :
                    multiSelectedFields
                }
                onChange={((e, newValue) => handleMultiSelectedFields(e, newValue, index))}
                renderTags={(value, getTagProps) =>
                    <div className='param-multiselect-chip'>
                        {value.map((option, index) => (
                            <Chip
                                key={option.value}
                                label={option.label}
                                {...getTagProps({ index })}
                                sx={{ margin: '8px 6px 0px 6px !important' }}
                            />
                        ))}
                    </div>
                }
                renderOption={(props, option) => (

                    <MenuItem
                        {...props}
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                )}
                renderInput={(params) => (
                    <ASTextField
                        {...params}
                        variant="outlined"
                        label={parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList ?
                            (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.filter(option => option.isSelected)?.length > 0 
                            ? `${parameter?.displayName} (${state.dynamicListOptions?.[complexTypeValue?.parameterName]?.filter(option => option.isSelected).length} Selected)` 
                            : parameter?.displayName) 
                            :
                            (multiSelectedFields?.length > 0 || params?.InputProps?.value?.length > 0 
                            ? `${parameter?.displayName} (${multiSelectedFields.length} Selected)` 
                            : parameter?.displayName)
                          }
                        InputLabelProps={memoizedMultiSelectDropdownInputLabelProps}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                padding:"0px !important",
                                '& .MuiAutocomplete-clearIndicator': {
                                    display: 'none',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    display: 'none',
                                },
                                '& .MuiAutocomplete-input': {
                                    padding: '7.5px 4px 7.5px 9px !important',
                                }
                            }
                        }}
                        sx={{
                            '& [data-shrink="false"]': {
                            top: '-9px !important', 
                            }
                        }}
                    />
                )}
                sx={{ width: "100%"}}
            />
        :
        <>
            {parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput || parameter.parameterTypeCD === AppEnum.ParameterTypeValue.Fixed ?
                < ASTextField
                    key={index}
                    className="text-field1 label-text-field "
                    type={parameter?.sysDataTypeId ===  AppEnum.DataTypeId.Date ? "date" : parameter?.sysDataTypeId === AppEnum.DataTypeId.Number ? "number" : "text"}
                    fullWidth
                    label={parameter?.displayName}
                    variant="outlined"
                    disabled={parameter?.parameterTypeCD === "Fixed" ? true : false}
                    onChange={(e) => handleOnInputChange(e, index, false)}

                    defaultValue={parameter?.sysDataTypeId === AppEnum.DataTypeId.Date ? moment(dateObject).format(AppEnum.DateFormats.Y_M_D) : isNullOrEmpty(parameter?.parameterValue) ? parameter.defaultValue : parameter?.parameterValue
                    }
                    InputLabelProps={{
                        shrink: parameter?.sysDataTypeId === AppEnum.DataTypeId.Date ? true : undefined,
                    }}
                    sx={{
                        '& [data-shrink="false"]': {
                          top: '-9px !important',
                        }
                    }}
                /> : null
            }

            {
                parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable ?
                    parameter?.sysDataTypeId ===  AppEnum.DataTypeId.List ?
                        < ASTextField
                            size="small"
                            className="text-field1 label-text-field "
                            type={"text"}
                            sx={{ fontSize: "12px" }}
                            fullWidth
                            defaultValue={!parameter?.parameterValue ? complexTypeValue?.find((i) => i.Default)?.Value : parameter?.parameterValue}
                            label={parameter?.displayName}
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                },
                            }}
                            onChange={(e) => handleOnInputChange(e, index, true, false)}
                        >
                            {complexTypeValue?.map((list, index) => {
                                return (
                                    <MenuItem sx={{ fontSize: "12px" }} value={list?.Value} key={`dynamic ${index}`} >
                                        {list.Label}
                                    </MenuItem>
                                );
                            })}
                        </ASTextField>
                        :<>
                        <div className="default-value-dropdown">
                            <Stack spacing={2}>
                            <Autocomplete
                                size="small"
                                freeSolo
                                className="text-field1 label-text-field"
                                options={
                                    (state?.dynamicListOptions?.[complexTypeValue?.parameterName] || []).map(option => ({
                                        value: option?.value,
                                        label: option?.label,
                                    })) || []
                                }                                
                                renderOption={(props, option) => (
                                        <MenuItem
                                            {...props}
                                            key={`option-${option?.value}`}
                                            className={
                                                memoizedDynamicListValue === option?.label
                                                    ? "selected-dynamic-opt"
                                                    : "menu-role-div"
                                            }
                                        >
                                            {option?.label}
                                        </MenuItem>
                                )}
                                onChange={(event, value) => {
                                    const newValue = value ? value?.value : "";
                                    handleOnInputChange({ target: { value: newValue } }, index, true, true);
                                }}
                                value={memoizedDynamicListValue}
                                PopperComponent={StyledParamAutocompletePopper}
                                renderInput={(params) => (
                                    <ASTextField
                                        sx={{ fontSize: "12px" }}
                                        {...params}
                                        label={parameter?.displayName}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: memoizedDynamicListValue && params.InputProps.endAdornment,
                                        }}
                                    />
                                )}
                                />
                            </Stack>                                  
                        </div>                        
                    </>: null
            }
        </>}
        </div >
    )
}