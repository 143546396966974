import React, { useEffect, useState } from "react";
import AddNewVariablePopup from "../../../components/connections/variables-list/AddNewVariablePopup";
import * as UserService from "../../../services/variable-service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { AppEnum } from "../../../constants/app-enum";
import AddNewUserDefinedListPopup from "../../../components/connections/variables-list/AddUserDefinedListPopup";
import { getColumnDetails, getDynamicListCollection } from "../../../services/data-collection.service";
// new user request model
export class NewUserModel {
  uId = "";
  name = "";
  description = "";
  sysDataTypeId = 0;
  isTemplateVariable = false;
  templateConnectionId = null;
  primitiveTypeValue = "";
  complexTypeValue = [];
}

export class UpdateUserModel {
  uId = "";
  name = "";
  description = "";
  sysDataTypeId = 0;
  templateConnectionId = null;
  primitiveTypeValue = "";
  complexTypeValue = "";
}

export class DynamicList {
  DefaultValue = "";
  DataCollectionId = 0;
  DataCollectionUId = "";
  DisplayColumn = "";
  ValueColumn = "";
  isDefaultValueCorrect = true;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingRight: "20px",
  paddingLeft: "32px",
  paddingBottom: "17px",
  paddingTop: "12px",
  minHeight: "180px",
};

class UserDefinedList {
  Label = "";
  Value = "";
  Default = false;
}



export default function VariablesPopup(props) {


  const { variableUId } = props;
  const { variableDataType, setCompletedStatus, sysDataTypeId, connectionList, templateCompany } = props;
  const [formData, setFormData] = useState({
    name: "",
    dataType: "",
    primitiveTypeValue: "",
    sysDataTypeId: "",
  });

  useEffect(() => {
    if (variableDataType !== undefined && variableDataType !== AppEnum.DataTypeName.UserDefinedVariable) {
      setShownumberInput(false);
      setIsString(false);
      setUserList(false);
      setShowAddValue(false);
      setSelectedDataType(variableDataType); 

      setId(sysDataTypeId);
      formData.primitiveTypeValue = "";
      if(variableDataType ===  AppEnum.VariableDataTypes.Date){
        formData.primitiveTypeValue = moment(new Date()).format( AppEnum.DateFormats.Y_M_D);

      }
      else if(variableDataType ===  AppEnum.VariableDataTypes.Boolean) {

        formData.primitiveTypeValue = false;
      }
      else if (variableDataType === AppEnum.VariableDataTypes.Number){

        setShownumberInput(true);
      }
      else if(variableDataType === AppEnum.VariableDataTypes.Text){

        setIsString(true);
      }else if(variableDataType === AppEnum.VariableDataTypes.User_List){
        setUserList(true);
        setShowAddValue(true);
      }
    }
  }, [variableDataType])

  const navigate = useNavigate();

  useEffect(() => {
    getCollectionTypeList();
  }, []);

  useEffect(() => {
    checkIfSaveDisabled();
  });

  useEffect(() => {
    setIsDynamic(false);
    setUserDefinedValues(Array.from({ length: 5 }, () => new UserDefinedList()));
    if (variableUId && props.open) {
      setShownumberInput(false);
      setShowDynamicFields(false);
      setIsString(false);
      setUserList(false)
      setIsDynamicListValues(new DynamicList());
      setColumnList([]);
      UserService.getUserDefinedVariableByUId(variableUId)
        .then((response) => {
          if (response?.data) {
            const dataType = dataTypeOptions?.find(item => item.dataTypeName === response?.data?.dataTypeName);

            setSelectedDataType(dataType.displayLabel);
            setId(response?.data?.sysDataTypeId);
            setFormData((prevData) => ({ ...prevData, primitiveTypeValue: response?.data?.primitiveTypeValue, name: response?.data?.name, templateConnectionId: response?.data?.templateConnectionId, isTemplateVariable: response?.data?.isTemplateVariable, dataType: response?.data?.dataTypeName === AppEnum.DataTypeName.List ? AppEnum.DataTypeName.UserDefinedList : response?.data?.dataTypeName }));

            let dataTypeName = response?.data?.dataTypeName;
            const dummyVarible = dataTypeName === AppEnum.DataBaseDataType.Number ? setShownumberInput(true) : dataTypeName === AppEnum.DataTypeName.String ? setIsString(true) : null;

            const boolVarible = dataTypeName === AppEnum.DataTypeName.Boolean ? response?.data?.primitiveTypeValue === "false" ? false : true : null;
            if (boolVarible !== null) {
              setFormData((prevData) => ({ ...prevData, primitiveTypeValue: boolVarible }));
            }

            if (response?.data?.primitiveTypeValue === null) {
              const dummyVarible = dataTypeName === AppEnum.DataBaseDataType.Date ? moment(new Date()).format(AppEnum.DateFormats.Y_M_D) : dataTypeName === AppEnum.DataTypeName.Boolean ? false : null;
              setFormData((prevData) => ({ ...prevData, primitiveTypeValue: dummyVarible }));
            }

            if (dataTypeName === AppEnum.DataTypeName.List || dataTypeName === AppEnum.DataTypeName.DynamicList) {
              setSelectedDataType(dataTypeName === AppEnum.DataTypeName.List ? AppEnum.DataTypeName.UserDefinedList : dataTypeName);
              const parsedData = JSON.parse(response?.data?.complexTypeValue);

              if (dataTypeName === AppEnum.DataTypeName.DynamicList) {
                setIsDynamic(true);
                setShowDynamicFields(true);
                if (parsedData && parsedData?.DataCollectionUId !== "") {
                  setShowDynamicFields(false);
                  getDynamicList(parsedData);
                  onChangeDynamicListHandler(parsedData?.DataCollectionId);
                }
                if (parsedData && parsedData?.DataCollectionUId === "") {
                  setIsDynamicListValues(parsedData);
                }
                else {
                  setIsDynamicListValues(new DynamicList());
                }
              }
              else {
                const dummyVarible = parsedData === null ? setUserDefinedValues(Array.from({ length: 5 }, () => new UserDefinedList())) : (setUserDefinedValues(parsedData), setUserList(true));
                setShowAddValue(true);
              }
            }
          }
        });
    }
  }, [props.open])

  const [showAddValue, setShowAddValue] = useState(false);
  const [userList, setUserList] = useState(false);
  const [showNumberInput, setShownumberInput] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [id, setId] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [isBoolean, setIsBoolean] = useState(false);
  const [isString, setIsString] = useState(false);
  const [showDynamicFields, setShowDynamicFields] = useState(false);

  // Handle change for all input fields
  const handleChange = (field, value) => {
    setUpdateError("");
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };


  const onConnectionChange = (event) => {
    formData.templateConnectionId = event?.target?.value;
    checkIfSaveDisabled();
  }

  const [dataTypeOptions, setDataTypeOptions] = useState([]);

  // Define state variable for selected data type
  const [selectedDataType, setSelectedDataType] = useState("");

  //Handle change for data type select
  const handleDataTypeChange = (event) => {
    setIsString(false);
    formData.primitiveTypeValue = "";

    setIsDynamic(false);
    setShowDynamicFields(false);
    const selectedValue = event.target.value;
    setSelectedDataType(selectedValue);

    // Find the selected data type from the list
    const selectedDataType = dataTypeOptions.find(
      (option) => option.displayLabel === selectedValue
    );

    if (selectedValue == AppEnum.DataTypeName.DynamicList) {
      setIsDynamic(true);
      setIsDynamicListValues(new DynamicList());
      setColumnList([]);
      setDynamicList([]);
      getDynamicList();
    }
    // Check if isPrimitive is false
    if (selectedDataType && selectedDataType?.displayLabel === AppEnum.DataTypeName.UserDefinedList) {
      setShowAddValue(true);
    }
    else {
      setShowAddValue(false);
    }

    if (selectedDataType.dataTypeName === AppEnum.DataTypeName.Boolean) {
      setFormData((prevData) => ({
        ...prevData,
        ["primitiveTypeValue"]: false,
      }));
    }

    if (selectedDataType.dataTypeName === AppEnum.DataTypeName.String) {
      formData.primitiveTypeValue = "";
      setIsString(true);
    }


    // Array of data types that should set shownumberInput to true
    const numberInputDataTypes = [AppEnum.DataBaseDataType.Number, AppEnum.DataBaseDataType.Currency, AppEnum.DataBaseDataType.Date, AppEnum.DataBaseDataType.DateTime];

    // Check if the selected data type is in the array
    setShownumberInput(selectedDataType && numberInputDataTypes.includes(selectedDataType.dataTypeName));

    setId(selectedDataType?.id);

    if (selectedDataType.dataTypeName === AppEnum.DataBaseDataType.Date) {
      formData.primitiveTypeValue = moment(new Date()).format(AppEnum.DateFormats.Y_M_D);
    }

    if (selectedDataType.dataTypeName === AppEnum.DataBaseDataType.DateTime) {
      formData.primitiveTypeValue = new Date().toISOString().slice(0, 16);
    }


    if (variableUId?.length > 0) {
      formData.primitiveTypeValue = "";

      if ((formData.dataType !== selectedDataType.dataTypeName) && selectedDataType?.displayLabel !== AppEnum.DataTypeName.UserDefinedList) {
        formData.primitiveTypeValue = "";
      }
      if ((formData.dataType !== selectedDataType.dataTypeName) && selectedDataType?.displayLabel === AppEnum.DataTypeName.UserDefinedList) {
        formData.primitiveTypeValue = "";
        setShowAddValue(true);
        setIsBoolean(false);
        setIsString(false);

      }
      if ((formData.dataType !== selectedDataType.dataTypeName) && (formData.dataType === AppEnum.DataTypeName.Boolean)) {
        setIsBoolean(false);
      }
      if ((formData.dataType !== selectedDataType.dataTypeName) && (formData.dataType === AppEnum.DataTypeName.String)) {
        setIsString(false);
      }
      if (selectedDataType.dataTypeName === AppEnum.DataBaseDataType.Date) {
        setFormData((prevData) => ({
          ...prevData,
          dataType: selectedDataType.dataTypeName,
          primitiveTypeValue: moment(new Date()).format(AppEnum.DateFormats.Y_M_D),
        }));
      }

      if (selectedDataType.dataTypeName === AppEnum.DataBaseDataType.DateTime) {
        setFormData((prevData) => ({
          ...prevData,
          dataType: selectedDataType.dataTypeName,
          primitiveTypeValue: new Date().toISOString().slice(0, 16),
        }));
      }
    }
  };

  //add input box

  const [userDefinedValues, setUserDefinedValues] = useState(Array.from({ length: 5 }, () => new UserDefinedList()));
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isUserDefinedList, setIsUserDefinedList] = useState(false);
  const [isDynamic, setIsDynamic] = useState(false);
  const [isDynamicListValues, setIsDynamicListValues] = useState(new DynamicList());
  const [columnList, setColumnList] = useState([]);
  const [dynamicList, setDynamicList] = useState([]);

  const onChangeDynamicListHandler = async (id) => {
    const list = dynamicList?.find((item) => item?.id === id);
    try {
      setIsDynamicListValues(prev => ({
        ...prev,
        DataCollectionId: list?.id,
        DataCollectionUId: list?.uId,
        DisplayColumn: "",
        ValueColumn: ""
      }));
      const response = await getColumnDetails(id);
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        setColumnList(response?.data);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const onChangeValueColumnHandler = (field, value) => {
    if (field === "Display") {
      setIsDynamicListValues(prev => ({
        ...prev,
        DisplayColumn: value
      }));
    }
    else if (field === "Value") {
      setIsDynamicListValues(prev => ({
        ...prev,
        ValueColumn: value
      }));
    }
    else if (field === "Default") {
      setIsDynamicListValues(prev => ({
        ...prev,
        DefaultValue: value
      }));
    }
  };

  const getDynamicList = async (parsedData) => {
    try {
      const response = await getDynamicListCollection();
      if (response?.hasError) {
        toast.error(response?.errorMessage);
      }
      else {
        setDynamicList(response?.data);
        if (parsedData) {
          setIsDynamicListValues(parsedData);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  }


  useEffect(() => {
    if (userDefinedValues?.length < 5 && isUserDefinedList) {
      let len = 5 - userDefinedValues?.length;
      let emptyUserList = (Array.from({ length: len }, () => new UserDefinedList()));
      setUserDefinedValues([...userDefinedValues, ...emptyUserList]);
    }
  }, [isUserDefinedList]);

  const handleDialogClose = () => {
    //setUserList(false);
    setIsUserDefinedList(false);
    if (!variableUId) {
      setUserDefinedValues(Array.from({ length: 5 }, () => new UserDefinedList()));
    }
    else {
      let filterData = filterItem();
      setUserDefinedValues(filterData);
    }
  }

  // Step 2: Create a function to handle the addition of a new input box
  const handleAddInput = () => {
    if (!variableUId) {
      setUserDefinedValues(Array.from({ length: 5 }, () => new UserDefinedList()));
    }
    if (templateCompany === true && !formData.templateConnectionId) {
      setUpdateError("Please select connection");
    }
    else if (formData.name) {
      setIsUserDefinedList(true);
    }
    else {
      setUpdateError("Please enter variable name");
    }
  };

  const handleAddListInput = () => {
    setUserDefinedValues([...userDefinedValues, new UserDefinedList()]);
  }

  const handleDeleteListInput = (index) => {
    const newValues = [...userDefinedValues];
    newValues.splice(index, 1);
    if (userDefinedValues?.length <= 5) {
      setUserDefinedValues([...newValues, new UserDefinedList()]);
      return;
    }
    setUserDefinedValues(newValues);
  }

  const [labelDuplicate, setLabelDuplicate] = useState("");
  const [valueDuplicate, setValueDuplicate] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [backUpModel, setBackUpModel] = useState();

  const handleChange2 = (index, value, label) => {
    const newValues = [...userDefinedValues];
    if (label === "label") {
      newValues[index].Label = value;
    }
    else if (label === "value") {
      newValues[index].Value = value;
    }
    setUserDefinedValues(newValues);
  }

  const checkDuplicate = (filteredObject) => {
    const elementIndices = {};
    const duplicateIndices = [];

    filteredObject.forEach((element, index) => {
      if (element.trim() in elementIndices) {
        duplicateIndices.push(elementIndices[element] + 1, index + 1);
      } else {
        elementIndices[element] = index;
      }
    });

    const uniqueIndices = [...new Set(duplicateIndices)];
    return uniqueIndices.join(", ");
  }

  const filterItem = () => {
    const filteredObjects = userDefinedValues?.filter(item => item.Label !== "" || item.Value !== "");
    filteredObjects.map((list) => {
      list.Label = list.Label.trim();
      list.Value = list.Value.trim();

      if (list.Label == "" && list.Value != "") {
        list.Label = list.Value;
      }
    })
    if (filteredObjects?.length === 1) {
      filteredObjects[0].Default = true;
    }
    return filteredObjects;
  }

  const onCheckBoxClick = (event, index) => {
    const updatedList = userDefinedValues?.map((item, itemIndex) => {
      if (index === itemIndex) {
        return { ...item, Default: event?.target?.checked };
      } else {
        return { ...item, Default: false };
      }
    });
    setUserDefinedValues(updatedList);
  }

  const getCollectionTypeList = () => {
    let isVariable = true;
    UserService.getCollectionTypeList(isVariable, undefined).then((response) => {
      const updatedResponse = response?.data?.map((row) => {
        if (row?.dataTypeName === AppEnum.DataTypeName.DynamicList) {
          return {
            ...row, disabled: true
          };
        }
        return row;
      })

      setDataTypeOptions(updatedResponse);
    });
  };

  const saveHandler = () => {
    let filterData = filterUserDefinedData();
    let userModel = { ...NewUserModel };
    userModel.name = formData.name;
    userModel.description = "";
    userModel.sysDataTypeId = id;
    userModel.isTemplateVariable = formData.isTemplateVariable;
    userModel.templateConnectionId = formData.templateConnectionId;
    userModel.uId = variableUId;

    if (!showAddValue) {
      userModel.primitiveTypeValue = formData.primitiveTypeValue;
    }
    if (isDynamic) {
      userModel.complexTypeValue = JSON.stringify(isDynamicListValues);
    }
    if (isUserDefinedList) {
      userModel.complexTypeValue = JSON.stringify(filterData.filteredObject);
    }
    if (selectedDataType === AppEnum.DataTypeName.UserDefinedList) {
      if (filterData.duplicateLabel.length <= 0 && filterData.duplicateValue.length <= 0) {
        saveUserVariableApi(userModel);
      }
      else {
        setBackUpModel(userModel);
      }
    }
    else {
      saveUserVariableApi(userModel);
    }
  };

  const filterUserDefinedData = () => {
    var filteredObject = filterItem();

    let labelArray = [];
    let valueArray = [];

    filteredObject.forEach(element => {
      if (element.Value !== "") {
        valueArray.push(element.Value);
      }
      labelArray.push(element.Label);
    });

    var duplicateValue = checkDuplicate(valueArray);
    var duplicateLabel = checkDuplicate(labelArray);

    if (isUserDefinedList && (duplicateLabel.length > 0 || duplicateValue.length > 0)) {
      setLabelDuplicate(duplicateLabel);
      setValueDuplicate(duplicateValue);
      setConfirmationModalOpen(true);
    }
    let model = {
      duplicateValue: duplicateValue,
      duplicateLabel: duplicateLabel,
      filteredObject: filteredObject,
    }
    return model;
  }

  const updateHandler = () => {
    let filterData = filterUserDefinedData();
    let updateModel = { ...NewUserModel };

    updateModel.uId = variableUId;
    updateModel.name = formData.name;
    updateModel.description = "";
    updateModel.sysDataTypeId = Number(id);
    updateModel.isTemplateVariable = formData.isTemplateVariable
    updateModel.templateConnectionId = formData.templateConnectionId;

    if (!showAddValue) {
      updateModel.primitiveTypeValue = formData.primitiveTypeValue;
    }
    if (isDynamic) {
      updateModel.complexTypeValue = JSON.stringify(isDynamicListValues);
    }
    if ((variableUId && selectedDataType === AppEnum.DataTypeName.UserDefinedList) && filterData.filteredObject.length > 0) {
      updateModel.complexTypeValue = JSON.stringify(filterData.filteredObject);
    }
    if (isUserDefinedList && selectedDataType === AppEnum.DataTypeName.UserDefinedList) {
      if (filterData.duplicateLabel.length <= 0 && filterData.duplicateValue.length <= 0) {
        updateUserVariableApi(updateModel);
      }
      else {
        setBackUpModel(updateModel);
      }
    }
    else {
      updateUserVariableApi(updateModel);
    }
  }


  const onPopUpSave = () =>{
    setConfirmationModalOpen(false);
    if(variableUId){

      updateUserVariableApi(backUpModel);
    }
    else {
      saveUserVariableApi(backUpModel);;
    }
  }

  const updateUserVariableApi = async (updateModel) => {
    try {
      const response = await UserService.updateUserVariable(updateModel, variableUId);
      if (response?.hasError) {
        setUpdateError(response?.errorMessage);
        toast.error(response?.errorMessage);
      }
      else {
        props?.setShowList(false);
        setConfirmationModalOpen(false);
        props.handleCloseDialog();
        handleDialogClose();
        props.getUsersDefinedList();
        resetFormData();
        toast.success("Variable updated successfully.")
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const saveUserVariableApi = async (userModel) => {
    try {
      const response = await UserService.saveUserVariable(userModel);
      if (response?.hasError) {
        setUpdateError(response?.errorMessage);
        toast.error(response?.errorMessage);
      }
      else {

        if (variableDataType) {
          let model = {
            sysDataTypeId: userModel?.sysDataTypeId,
            uId: response?.data?.entityUId,
            label: userModel?.name
          }
          setCompletedStatus(model);
          handleDialogClose();
          resetFormData();
        }
        props?.setShowList(false);
        props.handleCloseDialog();
        props.getUsersDefinedList();
        resetFormData();
        handleDialogClose();
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const checkIfSaveDisabled = () => {
    const isNameEmpty = !formData?.name?.trim();
    const isDataTypeEmpty = !selectedDataType;

    const isPrimitiveValueEmpty = (showNumberInput && (!formData?.primitiveTypeValue
      || !formData?.primitiveTypeValue.trim()))
      || (isBoolean && !formData?.primitiveTypeValue)
      || (isString && !formData?.primitiveTypeValue);

    const areInputValuesEmpty = showAddValue && checkSaveDisabled;

    const dynamicListDataEmpty = isDynamic && !showDynamicFields && (!isDynamicListValues.DataCollectionId ||
      !isDynamicListValues?.ValueColumn || !isDynamicListValues?.DisplayColumn)

    const isTemplateCompany = templateCompany && !formData.templateConnectionId;
    const isDynamicDisabled = !templateCompany && showDynamicFields && isDynamicListValues?.DefaultValue === "";

    const isDisabled =
      isNameEmpty ||
      isDataTypeEmpty ||
      isPrimitiveValueEmpty ||
      areInputValuesEmpty ||
      dynamicListDataEmpty ||
      isTemplateCompany ||
      isDynamicDisabled;

    setSaveDisabled(isDisabled);

    if (selectedDataType !== "User Defined List" && isPrimitiveValueEmpty) {
      setSaveDisabled(true);
    }
    else if (selectedDataType === "User Defined List" && !isNameEmpty) {
      let bool = checkSaveDisabled();
      setSaveDisabled(bool);
    }
  };

  useEffect(() => {
    checkSaveDisabled();
  }, [userDefinedValues])

  const checkSaveDisabled = () => {
    setIsSaveDisabled(true);
    const isUserDefinedListEmpty = userDefinedValues?.map((item) => {
      if (item.Label !== "" || item.Value !== "") {
        return false;
      }
      else {
        return true;
      }
    })
    if (isUserDefinedListEmpty?.includes(false)) {
      setIsSaveDisabled(false);
    }
    return isSaveDisabled;
  }

  const resetFormData = () => {
    setFormData({
      name: "",
      dataType: "",
      primitiveTypeValue: "",
      sysDataTypeId: "",
    });
    setSelectedDataType("");
    setShowAddValue(false);
    setUpdateError("")
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const onVariableCheckBox = (field, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: checked,
    }));
    setIsBoolean(checked);
  }

  return (
    <div>
      <AddNewVariablePopup
        dataTypeOptions={dataTypeOptions}
        isDynamic={isDynamic}
        isDynamicListValues={isDynamicListValues}
        columnList={columnList}
        dynamicList={dynamicList}
        connectionList={connectionList}
        onConnectionChange={onConnectionChange}
        templateCompany={templateCompany}
        onChangeDynamicListHandler={onChangeDynamicListHandler}
        onChangeValueColumnHandler={onChangeValueColumnHandler}
        handleDataTypeChange={handleDataTypeChange}
        setDataTypeOptions={setDataTypeOptions}
        selectedDataType={selectedDataType}
        handleChange={handleChange}
        formData={formData}
        showAddValue={showAddValue}
        handleAddInput={handleAddInput}
        saveHandler={saveHandler}
        showNumberInput={showNumberInput}
        saveDisabled={saveDisabled}
        variableUId={variableUId}
        updateHandler={updateHandler}
        updateError={updateError}
        open={props.open}
        userDefinedValues={userDefinedValues}
        userList={userList}
        onVariableCheckBox={onVariableCheckBox}
        isBoolean={isBoolean}
        handleCloseDialog={() => {
          handleDialogClose();
          props.handleCloseDialog();
          resetFormData();
        }}
        variableDataType={variableDataType}
        showDynamicFields={showDynamicFields}
      />
      <AddNewUserDefinedListPopup
        userDefinedValues={userDefinedValues}
        handleAddListInput={handleAddListInput}
        handleDeleteListInput={handleDeleteListInput}
        handleChange2={handleChange2}
        saveHandler={saveHandler}
        updateHandler={updateHandler}
        onCheckBoxClick={onCheckBoxClick}
        isSaveDisabled={isSaveDisabled}
        variableUId={variableUId}
        open={isUserDefinedList}
        formData={formData}
        style={style}
        onPopUpSave={onPopUpSave}
        isOpen={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirm={closeConfirmationModal}
        valueDuplicate={valueDuplicate}
        labelDuplicate={labelDuplicate}
        selectedDataType={selectedDataType}
        message="Do you still want to continue ?"
        noButtonLabel="NO"
        yesButtonLabel="YES"
        handleDialogClose={() => {
          handleDialogClose();
        }}
      />
    </div>
  );
}
