import { Autocomplete, MenuItem } from '@mui/material';
import { ASTextField } from '../as-text-fields';

const ASAutoComplete = (props) => {

    const { options, selectedOptionValue, onChange, item, value, label, tableContainerRef, onBlur } = props;

    return (
        <Autocomplete
            size="small"
            id="variable-dropdown"
            freeSolo
            className="text-field"
            options={options}
            renderOption={(props, option) => {
                return (
                    <MenuItem
                        {...props}
                        key={`option ${option?.value}`}
                        className={selectedOptionValue === option?.key ? "autocomplete-selected-option" : "autocomplete-option"}
                    >
                        {option?.label}
                    </MenuItem>
                );
            }}
            onChange={(event, option) => {
                onChange(option?.key, item)
            }}

            value={value}
            onOpen={() => {
                document.body.style.overflowY = 'hidden';
                if (tableContainerRef.current) {
                    tableContainerRef.current.style.overflow = 'hidden';  // Disable scroll
                }
            }}
            onClose={() => {
                document.body.style.overflowY = 'auto';
                if (tableContainerRef.current) {
                    tableContainerRef.current.style.overflow = 'auto';
                }
            }}
            onBlur={(event, option) => onBlur?.(event, item)}
            renderInput={(params) => (
                <ASTextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: item?.defaultValue && params.InputProps.endAdornment,
                    }}
                />
            )}
        />
    )
}

export default ASAutoComplete