import "./VariableList.scss";
import "./../../user-permission/user-permission.scss"
import { Paper } from "@mui/material";
import styles from "../../../styles/_exports.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useMemo } from "react";
import TableMainHeader from "../../../shared/misc/table-main-header";


const SystemDefinedVariableList = (props) => {

    const { state, onFocusSearchBoxHandler, onBlurSearchBoxHandler, onChangeSearchUserVariableHandler, searchInputRef, isShrunk } = props;

    const users = useMemo(() => state.filteredUsers || [], [state.filteredUsers])


    return (
        <div className="system-defined-variables-container">

            <TableMainHeader
                totalRows={users?.length}
                tableTitle="System Defined Variables"
                hasSearchField={true}
                isShrunk={isShrunk}
                onFocusSearchBoxHandler={onFocusSearchBoxHandler}
                onBlurSearchBoxHandler={onBlurSearchBoxHandler}
                onChangeSearchUserVariableHandler={onChangeSearchUserVariableHandler}
                searchInputRef={searchInputRef}
                parentClassName={'parent-variable-header'}
                countClassName={'variable-count-div'}

            />

            <Paper className="user-list-paper">
                <TableContainer className="tableHeight">
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        <TableBody>

                            {
                                users?.map((user, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => props.editUserPermission(user)}
                                            key={index}
                                            className="user-table-row"
                                        >
                                            <TableCell
                                                align="left"
                                                sx={{ color: styles.secondaryColor }}
                                            >
                                                {user?.displayName}
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                sx={{ color: styles.mutedColor, padding: "0px 16px" }}
                                            >
                                                {user?.defaultValue}
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                sx={{ color: styles.mutedColor }}
                                            >
                                                {user?.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default SystemDefinedVariableList
