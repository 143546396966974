import React, { useEffect, useRef, useState } from 'react'
import { DataCollectionModal, DataCollectionState } from '../add-new-data-collection/new-data-collection-state';
import ColumnSelectionController from '../column-selection/column-selection';
import { isNullOrEmpty } from '../../../services/data-source-validation';
import { useLocation, useNavigate } from 'react-router-dom';
import * as collectionService from '../../../services/data-collection.service';
import * as connectionService from '../../../services/data-source.service';
import { toast } from 'react-toastify';
import { AppEnum } from '../../../constants/app-enum';
import { authorizeAgain, getToken } from '../../../services/authorize-again-service';
import { sortMethod } from '../../../shared/code-challanges/code-challanges';
import { getTemplateCollectionParameters, updateParameterValues } from '../utils/template-collection-parameter';

export function filterCollectionParams(collectionParameters){
    return collectionParameters.filter(
      obj => !/(filter|sort|limit)/i.test(obj.parameterName)
    );
}

export function checkOtherParams(collectionParameters){
    return collectionParameters?.some(
        param => (param.parameterTypeCD.toLowerCase() !== AppEnum.ParameterType.FixedHidden.toLocaleLowerCase() && 
            param.parameterName.toLowerCase() !== "filter" &&
            param.parameterName.toLowerCase() !== "sort" &&
            param.parameterName.toLowerCase() !== "limit"
    ));
}

export default function DataVisualizerController(props) {
    const {isForDataCollections, closeWindowOnPopupClose, collectionUId, setShowDataVisualizer, showDataVisualizer} = props;
    const location = useLocation();
    const navigate = useNavigate();

    const [queryState, setQueryState] = useState()
    const [state, setState] = useState(new DataCollectionState());
    const [selectedConnection, setSelectedConnection] = useState([]);
    const [isCloudLoading, setIsCloudLoading] = useState(false);
    const [prevCloudFilterationData, setPrevCloudFilterationData] = useState({
        prevFilterData: [],
        prevSortData: [],
        prevLimitData: null,
        templateCollectionId: null
    });
    const [isCloudFilterationData, setIsCloudFilterationData] = useState(false);
    const [isSearchOptionsAvailable, setIsSearchOptionsAvailable] = useState(false);
    const [searchOptionsParameters, setSearchOptionsParameters] = useState([]);
    const [maunalColumns,setManualColumns] = useState([]);
    const [columnOverriden,setColumnOverriden] = useState(false);

    useEffect(() => {
        if(!showDataVisualizer){
            const urlParams = new URLSearchParams(window.location.search);
            storeQueryDetails(urlParams);
            setIsCloudLoading(true);
        }
        else{            
            setQueryState(
                {
                    CollectionUId: collectionUId,
                    CloseWindowOnPopupClose: closeWindowOnPopupClose,
                    isForDataCollections: isForDataCollections,
                }
            )
        }
    }, [])

    useEffect(() => {
        if (state?.connectionUId && queryState?.CollectionUId) {
            getCurrentConnectionDetails(state.connectionUId);
            getDisplayFormatList();
        }
    }, [state?.connectionUId, queryState?.CollectionUId])

    useEffect(() => {
        if (state?.collectionParameters.length > 0 && prevCloudFilterationData?.templateCollectionId === null && queryState?.CollectionUId) {
            const filterParameter = state?.collectionParameters.filter(i => i.parameterName == "Filter");
            const sortParameter = state?.collectionParameters.filter(i => i.parameterName == "Sort");
            const limitParameter = state?.collectionParameters.filter(i => i.parameterName == "Limit");
            if (filterParameter && filterParameter?.length > 0) {
                const filter = JSON.parse(filterParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevFilterData: filter,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
            if (sortParameter && sortParameter?.length > 0) {
                const sort = JSON.parse(sortParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevSortData: sort,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
            if (limitParameter && limitParameter?.length > 0) {
                const limit = JSON.parse(limitParameter[0].defaultValue);
                setPrevCloudFilterationData(prevState => ({
                    ...prevState,
                    prevLimitData: limit,
                    templateCollectionId: state?.templateCollectionId
                }));
            }
        }
    }, [state?.collectionParameters])

    useEffect(() => {
        if ((selectedConnection && !state?.templateCollectionId) || queryState?.CollectionUId) {
            getCollectionObjectByType();
        }
    }, [selectedConnection]);

    useEffect(() => {
        if (state?.cloudCollectionObjects.length > 0 && state?.templateCollectionId){
            let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state?.templateCollectionId);
            if (object){
                getTemplateCollectionParameters(object?.templateCollectionUId, setState, setIsSearchOptionsAvailable, setSearchOptionsParameters, setManualColumns, setColumnOverriden);
            }
        }
    }, [state?.cloudCollectionObjects, state?.templateCollectionId])    

    const getCollectionDetails = () => {

        collectionService.getCollectionDetails(queryState.CollectionUId)
            .then((response) => {
                if (response?.data) {
                    let prevCollection = response?.data;
                    if (prevCollection) {
                        setState((prevState) => {

                            return {
                                ...prevState,
                                uId: prevCollection.uId,
                                sysCollectionTypeId: prevCollection.sysCollectionTypeId,
                                sysConnectionTypeId: prevCollection.sysConnectionTypeId,
                                sysCollectionSubTypeId: prevCollection.sysCollectionSubTypeId,
                                childGroupName: prevCollection.childGroupName,
                                groupName: prevCollection.groupName,
                                sourceName: prevCollection.sourceName,
                                sourceNamePart2: prevCollection.sourceNamePart2,
                                sourceNamePart4: prevCollection.sourceNamePart4,
                                isCollectionAsDataSource: prevCollection.isCollectionAsDataSource,
                                hasPrintableCollection: prevCollection.hasPrintableCollection,
                                collectionColumns: prevCollection.collectionColumns,
                                collectionParameters: prevCollection.collectionParameters,
                                collectionFilterColumns: prevCollection.collectionFilterColumns,
                                name: prevCollection.name,
                                connectionUId: prevCollection.connectionUId,
                                categoryUId: prevCollection.categoryUId,
                                restSendAsCD: prevCollection.restSendAsCD,
                                restDataTransformationScript: prevCollection.restDataTransformationScript,
                                restBody: prevCollection.restBody,
                                restRequestMethod: prevCollection.restRequestMethod,
                                prevCollection: prevCollection,
                                basicInfoError: null,
                                templateCollectionId: prevCollection.templateCollectionId,
                                isFilterSupported: prevCollection.isFilterSupported,
                                isSortSupported: prevCollection.isSortSupported,
                                isPagingSupported: prevCollection.isPagingSupported,
                                areAllFieldsFilterable: prevCollection.areAllFieldsFilterable,
                                areAllFieldsSortable: prevCollection.areAllFieldsSortable,
                                columnApiEndpoint: prevCollection.columnApiEndpoint,
                                variableId: prevCollection.variableId,
                                returnRawData: prevCollection.returnRawData
                            }
                        })
                        if (prevCollection.isTemplateCollection && prevCollection.isInheritCollection) {
                            setState((prevState) => { return { ...prevState, uId: null } });
                        }
                        if (prevCollection?.collectionParameters?.length > 0){
                            const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);
                              if (hasOtherParams){
                                prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                                let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                setIsSearchOptionsAvailable(true);
                                setSearchOptionsParameters(updatedParams);
                              }
                              else{
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                              }
                        }
                        else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }
                        getCollectionTypeList(prevCollection.sysCollectionTypeId);
                    }
                }
                else if (response?.hasError)
                    setState((prevState) => { return { ...prevState, basicInfoError: response.errorMessage } });
            })
    }    

    const storeQueryDetails = (urlParams) => {

        const closeWindowOnPopupClose = urlParams.get('closeWindowOnPopupClose');
        const collectionUId = urlParams.get('collectionUId');
        const accessToken = urlParams.get('AccessToken');
        const refreshToken = urlParams.get('RefreshToken');
        const aSTenantUId = urlParams.get('ASTenantUId');
        const isForDataCollections = urlParams.get('isForDataCollections');

        setQueryState(
            {
                CollectionUId: collectionUId,
                CloseWindowOnPopupClose: closeWindowOnPopupClose?.toLowerCase() == "true" ? true : false,
                isForDataCollections: isForDataCollections
            }
        )

        if ((accessToken != null && refreshToken != null && aSTenantUId != null)) {

            localStorage.setItem("refresh_token", refreshToken);
            localStorage.setItem("token", accessToken);
            localStorage.setItem("ASTenantId", aSTenantUId);

            urlParams.delete('closeWindowOnPopupClose');
            urlParams.delete('AccessToken');
            urlParams.delete('RefreshToken');
            urlParams.delete('ASTenantUId');
            urlParams.delete('isForDataCollections');

            navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
        }
    }

    useEffect(() => {
        if (queryState != null) {
            getCollectionDetails();
        }
    }, [queryState])

    const getColumnSchema = async (prevCollection, callback = null) => {
        setIsCloudLoading(true);
        let dataCollectionModal;
        if (prevCollection) {
            dataCollectionModal = getDataCollectionModal(prevCollection);
        }
        else {
            dataCollectionModal = getDataCollectionModal(null);
        }

        if (queryState?.CollectionUId && state?.templateCollectionId) {
            delete dataCollectionModal.collectionParameters;
        }
        else {
            delete dataCollectionModal.collectionColumns;
        }

        const response = await collectionService.getColumnSchema(dataCollectionModal);

        if (response) {
            if (response?.hasError) {
                setState((prevState) => { return { ...prevState, columnSchemaError: response?.errorMessage, columnSchemaList: [] } });
                setIsCloudLoading(false);

                setState((prevState) => { return { ...prevState, cloudCollectionError: response } });

                if (response?.errorMessage  == "Unauthorized") {
                    toast.error("Your connection has been expired. Please establish the connection again");
                }
                else {
                    toast.error(response?.errorMessage);
                }
            }
            else {
                setState((prevState) => { return { ...prevState, collectionColumnSchema: response?.data?.data } });

                if (callback != null) {
                    callback(response?.data?.data);
                }
                setIsCloudLoading(false);
            }
        }
    }


    const getDataCollectionModal = (prevCollection) => {
        let dataCollectionModal = new DataCollectionModal();
        let collection;
        if (prevCollection)
            collection = prevCollection;
        else
            collection = state;

        dataCollectionModal.connectionUId = collection.connectionUId;
        dataCollectionModal.groupName = collection.groupName;
        dataCollectionModal.childGroupName = collection.childGroupName;
        dataCollectionModal.name = collection.name;
        dataCollectionModal.sysCollectionTypeId = collection.sysCollectionTypeId;
        dataCollectionModal.sysConnectionTypeId = collection.sysConnectionTypeId;
        dataCollectionModal.sysCollectionSubTypeId = collection.sysCollectionSubTypeId;
        dataCollectionModal.sourceName = collection.sourceName;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.sourceNamePart4 = collection.sourceNamePart4;
        dataCollectionModal.categoryUId = collection.categoryUId;
        dataCollectionModal.columnSchema = collection.columnSchema;
        dataCollectionModal.isCollectionAsDataSource = collection.isCollectionAsDataSource;
        dataCollectionModal.hasPrintableCollection = collection.hasPrintableCollection;
        dataCollectionModal.collectionParameters = collection.collectionParameters;
        dataCollectionModal.collectionFilterColumns = collection.collectionFilterColumns;
        dataCollectionModal.collectionColumns = collection.collectionColumns;
        dataCollectionModal.uId = collection.uId;
        dataCollectionModal.restRequestMethod = collection.restRequestMethod ? collection.restRequestMethod : state.restRequestMethod;
        dataCollectionModal.restBody = collection.restBody ? collection.restBody : state.restBody;
        dataCollectionModal.restSendAsCD = collection.restSendAsCD ? collection.restSendAsCD : state.restSendAsCD;
        dataCollectionModal.restDataTransformationScript = collection.restDataTransformationScript ? collection.restDataTransformationScript : state.restDataTransformationScript;
        dataCollectionModal.templateCollectionId = collection?.templateCollectionId;
        dataCollectionModal.sourceNamePart2 = collection.sourceNamePart2;
        dataCollectionModal.isFilterSupported = collection?.isFilterSupported;
        dataCollectionModal.isSortSupported = collection?.isSortSupported;
        dataCollectionModal.isPagingSupported = collection?.isPagingSupported;
        dataCollectionModal.areAllFieldsFilterable = collection?.areAllFieldsFilterable;
        dataCollectionModal.areAllFieldsSortable = collection?.areAllFieldsSortable;
        dataCollectionModal.columnApiEndpoint = collection?.columnApiEndpoint;
        dataCollectionModal.variableId = collection?.variableId;
        dataCollectionModal.returnRawData = collection.returnRawData;

        return dataCollectionModal;
    }

    //may be removed
    const resetErrorMessages = () => {
        setState((prevState) => {
            return {
                ...prevState, basicInfoError: null
                , columnSchemaError: null, testCollectionError: null
            }
        })
    }

    const getCurrentConnectionDetails = (connectionUId) => {
        connectionService.getDataSourceByUID(connectionUId).then((res) => {
            if (res?.data) {
                setSelectedConnection(res.data);
            }
        });
    }

    const getCollectionTypeList = (sysConnectionTypeId) => {

        collectionService.getCollectionTypeList(sysConnectionTypeId)
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, collectionTypeList: response.data } });
            })
    }

    //NTD
    const addNewDataCollection = () => {
        let dataCollectionModal = getDataCollectionModal();

        if (dataCollectionModal?.templateCollectionId) {
            setIsCloudLoading(true);
            const allColumns = state?.collectionColumnSchema?.filter(i => i.isSelected);
            const columns = allColumns.map((i) => {
                const currentColl = dataCollectionModal.collectionColumns.filter(c => c.columnName == i.columnName);
                if (currentColl.length > 0) {
                    return {
                        collectionId: currentColl[0].collectionId,
                        columnName: currentColl[0].columnName,
                        dataType: currentColl[0].dataType,
                        dataTypeName: currentColl[0].dataTypeName,
                        displayFormat: currentColl[0].displayFormat,
                        displayName: currentColl[0].displayName,
                        id: currentColl[0].id,
                        isDefault: currentColl[0].isDefault,
                        isSortable: currentColl[0].isSortable,
                        isFilterable: currentColl[0].isFilterable,
                        notQuerable: currentColl[0].notQuerable,
                        uId: currentColl[0].uId,
                        displayOrder: i.displayOrder ?? 0,
                    }
                }
                else {
                    const matchingFormat = findDisplayFormat(i.dataTypeName);
                    return {
                        ...i,
                        displayOrder: i.displayOrder ?? 0,
                        displayFormat: matchingFormat?.displayFormat ?? AppEnum.DefaultDisplayFormat.General,
                        dataType: matchingFormat?.sysDataTypeId ?? 6
                    };
                }
            })

            dataCollectionModal.collectionColumns = columns;
        }

        if ((queryState.CollectionUId && !state?.isTemplateCollection) || (queryState.CollectionUId && selectedConnection?.templateConnectionId)) {
            dataCollectionModal.uId = queryState.CollectionUId;
            updateDataCollection(dataCollectionModal);
        }
    };

    const findDisplayFormat = (dataTypeName) => {
        if (state?.displayFormatList.length > 0) {
            if (dataTypeName.toLowerCase() == "number") {
                dataTypeName = "Int32";
            }
            return state?.displayFormatList?.find(item => item.dataBaseDataType.toLowerCase() == dataTypeName.toLowerCase());
        }
    };

    const getDisplayFormatList = () => {

        collectionService.getDisplayFormats()
            .then((response) => {

                if (response?.hasError)
                    setState((prevState) => { return { ...prevState, columnSchemaError: response.errorMessage } });

                else if (response?.data) {
                    setState((prevState) => { return { ...prevState, displayFormatList: response?.data } });
                }
            })
    }

    const updateDataCollection = (dataCollectionModal) => {

        if (isNullOrEmpty(dataCollectionModal.uId) && state?.templateConnectionId) {
            dataCollectionModal.uId = queryState.CollectionUId;
        }

        collectionService.updateDataCollection(dataCollectionModal)
            .then((response) => {
                setIsCloudLoading(false);
                if (response?.hasError) {
                    toast.error(response?.errorMessage);
                }
                else {
                    resetErrorMessages();
                    toast.success("Collection updated successfully.");
                    if(showDataVisualizer){
                        setShowDataVisualizer(false);
                    }
                    if (queryState?.isForDataCollections) {
                        navigate('/data-collections');
                    }
                    else {
                        window.chrome?.webview?.postMessage('close_window');
                    }
                }

            })
    }

    const getCollectionObjectByType = () => {

        setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });

        let connectionTypeName = selectedConnection?.connectionTypeName?.toLocaleLowerCase();
        collectionService.getCollectionObjectByType(connectionTypeName)
            .then((response) => {

                if (response?.data && response?.data.length > 0) {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: response.data } });
                }
                else {
                    setState((prevState) => { return { ...prevState, cloudCollectionObjects: [] } });
                }
            })
    }    
    
    const windowRef = useRef();   
    const [openConnectionPopup, setOpenConnectionPopup] = useState(false);
    const [stateConnectionParameter, setStateConnectionParameter] = useState(false);
    const [isAuthorizeAgain, setIsAuthorizeAgain] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (openConnectionPopup) {
                getToken(selectedConnection, stateConnectionParameter, windowRef, setState,
                    openConnectionPopup, setOpenConnectionPopup, setIsAuthorizeAgain, null);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [openConnectionPopup])

    const handleAuthorizeAgain = async() =>{
        authorizeAgain(setState, selectedConnection, windowRef, setOpenConnectionPopup, setStateConnectionParameter);
    }

    return (
        <ColumnSelectionController
            columnOverriden={columnOverriden}
            setColumnOverriden={setColumnOverriden}
            maunalColumns={maunalColumns}
            setShowDataVisualizer={setShowDataVisualizer}
            showDataVisualizer={showDataVisualizer}
            getColumnSchema={getColumnSchema}
            resetErrorMessages={resetErrorMessages}
            state={state}
            setState={setState}
            prevStepRef={null}
            setSelectedConnection={setSelectedConnection}//will check if we want to show BasicInformationController
            selectedConnection={selectedConnection}
            saveCollection={addNewDataCollection}
            isBasicInfoSubmitted={true}
            setActiveStep={null}//
            activeStep={null}//
            isUpdatingCollection={isNullOrEmpty(queryState?.CollectionUId) ? false : true}
            isLoading={isCloudLoading}
            setIsLoading={setIsCloudLoading}
            getCollectionObjectByType={getCollectionObjectByType}
            prevCloudFilterationData={prevCloudFilterationData}
            isCloudFilterationData={isCloudFilterationData}
            setIsCloudFilterationData={setIsCloudFilterationData}
            queryState={queryState}
            handleAuthorizeAgain={handleAuthorizeAgain}
            isAuthorizeAgain={isAuthorizeAgain}
            isOpenInDialog={queryState?.isForDataCollections ? true : false}
            setSearchOptionsParameters={setSearchOptionsParameters}
            isSearchOptionsAvailable={isSearchOptionsAvailable}
            searchOptionsParameters={searchOptionsParameters}
            setIsSearchOptionsAvailable={setIsSearchOptionsAvailable}
        />
    )
}
