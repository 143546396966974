export const ASUsers = ({ flag, className }) => {
    return (
        <>
            <svg
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="21"
                viewBox="0 0 28 21"
            >
                <path id="account-multiple" d="M21.6,22.2V25H2V22.2s0-5.6,9.8-5.6,9.8,5.6,9.8,5.6M16.7,8.9a4.9,4.9,0,1,0-4.9,4.9,4.9,4.9,0,0,0,4.9-4.9m4.816,7.7A7.448,7.448,0,0,1,24.4,22.2V25H30V22.2s0-5.082-8.484-5.6M20.2,4a4.746,4.746,0,0,0-2.7.826,7,7,0,0,1,0,8.148,4.746,4.746,0,0,0,2.7.826,4.9,4.9,0,1,0,0-9.8Z" transform="translate(-2 -4)" 
                fill={flag ? "#FFF" : "#16365F"}  />
            </svg>
        </>
    );
};
