export const DataTypePriorityMap = {
    Int16: 'Int64',
    Int32: 'Int64',
    Int64: 'Int64',
    Double: 'Int64',
    Single: 'Int64',
    Decimal: 'Decimal',
    Currency: 'Decimal',
    Percentage: 'Decimal',
    DateTime: 'Date',
    Date: 'Date',
    Time: 'Date',
    TimeSpan: 'Date',
    Boolean: 'Boolean',
    DateTimeOffset: 'Date',
    String: 'String',
    Object: 'String',
    Guid: 'String',
    Byte: 'String',
    'Scientific notation': 'String',
    'Byte[]': 'String',
};


export const getEquivalentPriorityDataType = (displayFormatList, dataType) => {
    if (dataType) {
        const highestType = DataTypePriorityMap[dataType] || dataType;
        return displayFormatList.find(dt => dt.dataBaseDataType === highestType) || null;
    } else {
        const highestDataTypeObjects = displayFormatList.reduce((acc, dt) => {
            const highestType = DataTypePriorityMap[dt.dataBaseDataType] || dt.dataBaseDataType;

            if (!acc.some(item => item.dataBaseDataType === highestType)) {
                const dataTypeObject = displayFormatList.find(item => item.dataBaseDataType === highestType);
                if (dataTypeObject) {
                    acc.push(dataTypeObject);
                }
            }

            return acc;
        }, []);

        return highestDataTypeObjects;
    }
};


export const updateColumnDataTypes = (displayFormatList, columns) => {
    return columns?.map(column => {
        const highestPriorityType = DataTypePriorityMap[column.ColumnDataType] || column.ColumnDataType;
        if (column.ColumnDataType !== highestPriorityType) {
            let datatypeDisplayFormate = displayFormatList?.find(i => i.dataBaseDataType == highestPriorityType);
            return {
                    ...column,
                    ColumnDataType: datatypeDisplayFormate?.dataBaseDataType,
                    displayFormat: datatypeDisplayFormate?.displayFormat,
                    dataType: datatypeDisplayFormate?.sysDataTypeId
            };
        }
        return column;
    });
};