//import { updateDisplayOrder } from '../../../components/common/ag-grid/grid-data-helper';
import { AppEnum } from '../../../constants/app-enum';
import { DateParseVariable } from '../../../constants/date-parse-variable';
import * as collectionService from '../../../services/data-collection.service';
import { sortMethod } from '../../../shared/code-challanges/code-challanges';
import { checkOtherParams, filterCollectionParams } from '../DataVisualizer/data-visualizer-controller';

export const getTemplateCollectionParameters = (templateCollectionUId, setState, setIsSearchOptionsAvailable, setSearchOptionsParameters, 
    setManualColumns, setColumnOverriden ) => {
 
    collectionService.getCollectionDetails(templateCollectionUId)
        .then((response) => {
            if (response?.data) {
                let prevCollection = response?.data;
                if (prevCollection) {

                    if(prevCollection?.restDataTransformationScript){
                        setState((prevState) => { return { ...prevState, restDataTransformationScript: prevCollection?.restDataTransformationScript } });
                    }                   
                    const manuallyCreatedColumns = prevCollection.collectionColumns.filter(mc => mc.isManuallyCreated);
                    
                    if(manuallyCreatedColumns.length > 0){
                        setManualColumns(manuallyCreatedColumns);
                        setColumnOverriden(true);
                    }
                    else{
                        setManualColumns([]);
                        setColumnOverriden(false);
                    }

                    if (prevCollection.collectionParameters?.length > 0){
                        const hasOtherParams = checkOtherParams(prevCollection?.collectionParameters);
                     
                          if (hasOtherParams){
                            prevCollection["collectionParameters"] = filterCollectionParams(prevCollection?.collectionParameters)
                            let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                            setIsSearchOptionsAvailable(true);                        
                            setSearchOptionsParameters(updatedParams);
                          }
                          else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                          }
                    }
                    else{
                        setIsSearchOptionsAvailable(false);
                        setSearchOptionsParameters([]);
                    }
                }
            }
        })
}

export const updateParameterValues = (params) => {    
    const dateParser = new DateParseVariable();

    return params.map(param => {
        if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList && param?.complexTypeValue) {
            const complexType = JSON.parse(param.complexTypeValue);
            param.parameterValue = complexType?.DefaultValue
        }
        else if (param.sysDataTypeId == AppEnum.DataTypeId.List && param?.complexTypeValue) {
            const complexTypeArray = JSON.parse(param.complexTypeValue);
            const defaultItem = complexTypeArray?.find(item => item.Default === true);
            param.parameterValue = defaultItem?.Value;
        }
        else if(param.parseAtClientSide && param.sysDataTypeId === AppEnum.DataTypeId.Date){
            param.parameterValue = dateParser.parseVariable(param.defaultValue);
        }
        else {
            //param.parameterValue = param.defaultValue;
        }
        return param;
    });
};
