import MenuItem from '@mui/material/MenuItem';
import { ASTextField } from '../../../shared/as-text-fields';
import { ALL } from '../../../controllers/data-collections/data-collections-controller';
import SearchItemTextField from '../../../shared/misc/search-item-textfield';
import { Stack } from '@mui/material';


const SearchOrFilterVariablesHeader = (props) => {

    const { filterHeader, selectedConnectionValue, filterUserDefinedVariables, onFocusSearchBoxHandler,
        onBlurSearchBoxHandler, onChangeSearchUserVariableHandler, searchInputRef, isShrunk, connectionMenuList } = props


    return (
        <div>
            <div className="connection-search-div">
                <div className="collection-filters">
                    {
                        filterHeader.map((filter, index) => {

                            return (
                                <ASTextField
                                    key={index}
                                    className="role-text-field connextions-dropdown"
                                    label={`Filter by ${filter.filterName}`}
                                    variant="outlined"
                                    SelectProps={{ sx: { height: "60px", padding: "0px" } }}
                                    select={true}
                                    value={selectedConnectionValue}
                                    name={filter?.filterName}
                                >
                                    {
                                        connectionMenuList?.map((connection, index) => {

                                            return <MenuItem
                                                key={index}
                                                value={connection.uId}
                                                name={connection.name}
                                                sx={{ height: "45px" }}
                                                onClick={() =>
                                                    filterUserDefinedVariables(connection)
                                                }
                                            >
                                                <Stack direction={'row'} className="variable-filter-menu" >
                                                    {connection.uId !== ALL && <img src={`/assets/${connection.iconImage}`} alt='image-icon-alt' ></img>}
                                                    {connection.name}
                                                </Stack>
                                            </MenuItem>

                                        })}
                                </ASTextField>
                            )
                        })
                    }
                </div>

                <SearchItemTextField
                    isShrunk={isShrunk}
                    onFocusSearchBoxHandler={onFocusSearchBoxHandler}
                    onBlurSearchBoxHandler={onBlurSearchBoxHandler}
                    onChangeSearchUserVariableHandler={onChangeSearchUserVariableHandler}
                    searchInputRef={searchInputRef}
                />

            </div>
        </div>
    )
}

export default SearchOrFilterVariablesHeader