import { CardContent, Typography } from "@mui/material";
// import { useState,  Component } from "react";
import "./groups.scss";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import { ASTextField } from "../../shared/as-text-fields";
import GroupIconSvg from "./GroupIconSvg";
import { useSelector } from "react-redux";
import DefaultView from "../common/defaultView/DefaultView";

function  Groups(props) {
  const {
    groups,
    handleGroupToggle,
    openGroups,
    editableGroupIndex,
    handleEditGroup,
    handleSave,
    handleCancelEdit,
    handleEditSubgroup,
    editableSubgroupIndex,
    handleGroupChange,
    handleSubGroupChange,
    isUserAllowedToEdit
  } = props;

  const remainingFeatureBalance = useSelector((state) => state.asState.remainingFeatureBalance); 
  return (
    <>
      <div className="add-btn-div">
        <div>
          <div className="total-users-div">
            Total <span>{groups?.length}</span>
          </div>
          <div className="group-subgroup-lebel">Group and Sub Groups</div>
        </div>
      </div>

       

      {remainingFeatureBalance === null ? ("") :( groups?.length === 0 ? (
        <DefaultView
          title="You have not created any Groups and SubGroups yet."
          firstPart="Create a Collection to create your Groups and SubGroups."
        />
        
      ) : (groups?.map((group, groupIndex) => {
        const isGroupOpen = openGroups.includes(groupIndex);
        const isEditable =
          editableGroupIndex === groupIndex &&
          props.editableSubgroupIndex == null;

         

        return (
          <>
            
            <div key={groupIndex} container className="group-container">
              <div>
                <div className="edit-user-icon" />
                <span
                  onClick={() => handleGroupToggle(groupIndex)}
                  id={`${String(groupIndex)}+group-Crop54Icon`}
                >
                  {openGroups.includes(groupIndex) ? (
                    <AddIcon
                      style={{
                        color: "#16365F",
                        border: "1px solid #16365F",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  ) : (
                    <GroupIconSvg />
                  )}
                </span>
              </div>
              <div className="group-subgroup-lebel">
                {isEditable ? (
                  <ASTextField
                    style={{
                      width: "407px",
                      background: "#fff",
                      borderColor: "#70707054",
                    }}
                    className="text-field"
                    fullWidth
                    variant="outlined"
                    value={group?.groupName}
                    onChange={(event) =>
                      handleGroupChange(groupIndex, event.target.value)
                    }
                  />
                ) : (
                  group?.groupName
                )}
              </div>
              <div>
                {isEditable ? (
                  <div className="button-group">
                    <SaveIcon
                      style={{ color: "#16365F" }}
                      onClick={() => handleSave(groupIndex)}
                      variant="contained"
                    >
                      Save
                    </SaveIcon>
                    <HighlightOffIcon
                      style={{ color: "#16365F" }}
                      onClick={handleCancelEdit}
                      variant="contained"
                    >
                      Cancel
                    </HighlightOffIcon>
                  </div>
                ) : (
                  isUserAllowedToEdit && (
                    <EditIcon
                      style={{
                        cursor: "pointer",
                        color: "#16365F",
                        width: "15px",
                        height: "15px",
                      }}
                      id={`${String(groupIndex)}+group-EditIcon`}
                      className="edit-user-icon"
                      onClick={() => handleEditGroup(groupIndex)}
                    ></EditIcon>
                  )
                )}
              </div>
            </div>
            {group?.subGroups.map((subgroup, subgroupIndex) => {
              const isEditableSubgroup =
                editableSubgroupIndex === subgroupIndex &&
                editableGroupIndex === groupIndex;
              return (
                <div className="subGroup">
                  {!isGroupOpen && (
                    <div key={subgroupIndex} className="subgroup-container">
                      <div className="subgroup-row">
                        {isEditableSubgroup ? (
                          <ASTextField
                            style={{
                              width: "61%",
                              position: "absolute",
                              background: "#fff",
                              borderColor: "#70707054",
                              left: "-10px",
                            }}
                            value={subgroup?.name}
                            onChange={(event) => {
                              handleSubGroupChange(
                                groupIndex,
                                subgroupIndex,
                                event.target.value
                              );
                            }}
                          />
                        ) : (
                          <div className="subgroup-name">{subgroup?.name}</div>
                        )}

                        <div className="subgroup-collection">
                          {subgroup?.numberOfCollection} collections
                        </div>

                        {isEditableSubgroup ? (
                          <div className="button-group-first">
                            <SaveIcon
                              style={{ color: "#16365F" }}
                              onClick={handleSave}
                              variant="contained"
                            >
                              Save
                            </SaveIcon>
                            <HighlightOffIcon
                              style={{ color: "#16365F" }}
                              onClick={handleCancelEdit}
                              variant="contained"
                            >
                              Cancel
                            </HighlightOffIcon>
                          </div>
                        ) : (
                          isUserAllowedToEdit && (
                            <div>
                              <EditIcon
                                style={{
                                  cursor: "pointer",
                                  color: "#16365F",
                                  height: "20px",
                                }}
                                id={`${String(subgroupIndex)}+subgroup-EditIcon`}
                                className="edit-user-icon"
                                onClick={() =>
                                  handleEditSubgroup(groupIndex, subgroupIndex)
                                }
                              ></EditIcon>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        );
      })))}
    </>
  );
}

export default Groups;
