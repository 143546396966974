import React from "react";
import "./AcceptInvitation.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHeader } from "../../login-signup/login-signup";
import { ASTextField } from "../../../shared/as-text-fields";
import { ASButton } from "../../../shared/as-button/as-button";

export default function AcceptInvitationComponent(props) {
  const {
    handleToggleConfirmPasswordVisibility,
    handleSubmit,
    handleTogglePasswordVisibility,
    handleInputChange,
    formData,
    isPasswordVisible,
    isConfirmPasswordVisible,
    acceptInvitation,
    emailId,
    errorMessage,
    loading,
    buttonDisable,
    loggedInCheck,
    navigate,
  } = props; 

  return (
    <div className="acceptinvitation-container">
      {loading ? (
        <div className="as-form-spinner"></div>
      ) : (
        <>
          <div className="columns-column-1">
            <img src="./assets/login.svg" alt="LoginBanner" />
          </div>

          <div
            className={
              !acceptInvitation?.userExists
                ? "acceptinvitation-popup"
                : "acceptinvitation-popup-userexists"
            }
          >
            {buttonDisable ?
              <div className="as-form-spinner"></div>
              : ""}
            <div className="padding20">
              {FormHeader((!acceptInvitation?.userExists ? "Sign Up and Accept Invite" : "Accept Invitation"), false)}
            </div>

            {loggedInCheck ? <>
              <div className="login-check-heading">You are logged in with a different account. </div>
              <div className="login-check-heading">To accept the invite, please log out and accept the invitation with the same account.</div>
            </> : <>
              <div className="headeading">You are Accepting Invitation for</div>
              <div className="sub-headeading">
                {acceptInvitation?.organization}
              </div> </>}
            {errorMessage && (
              <div className="error-msg">
                <p>{errorMessage}</p>
              </div>
            )}

            {!acceptInvitation?.userExists && (
              <div>
                <div>
                  <ASTextField
                    className="text-field"
                    type={"text"}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={formData?.name}
                    disabled={true}
                  />
                </div>
                <div>
                  <ASTextField
                    className="text-field"
                    type={"text"}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={emailId}
                    disabled={true}
                  />
                </div>
                <div>
                  <ASTextField
                    className="text-field"
                    fullWidth
                    label=" Enter Password"
                    variant="outlined"
                    type={isPasswordVisible ? "text" : "password"}
                    value={formData.password}
                    onChange={handleInputChange("password")}
                  />
                  <span
                    className="eye-icon"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <VisibilityIcon />}
                  </span>
                </div>
                <div>
                  <ASTextField
                    className="text-field"
                    fullWidth
                    label="Confirm Password"
                    variant="outlined"
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    value={formData.confirmPassword}
                    onChange={handleInputChange("confirmPassword")}
                  />
                  <span
                    className="eye-icon"
                    onClick={handleToggleConfirmPasswordVisibility}
                  >
                    {isConfirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </span>
                </div>
              </div>
            )}
            {!loggedInCheck ?
              <div
                className={
                  !acceptInvitation?.userExists
                    ? "login-button"
                    : "login-button-userexists"
                }
              >
                <ASButton variant="contained" className="button-primary"
                  onClick={handleSubmit} disabled={buttonDisable}
                  primary={true}>
                  Accept Invitation
                </ASButton>

              </div> : <div className="login-button-userexists">

                <ASButton variant="contained" onClick={() => navigate("/")}
                  className="button-secondary" primary={true} >
                  Back
                </ASButton>                
              </div>}
          </div>
        </>
      )}
    </div>
  );
}
