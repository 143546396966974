import React from "react";
import '../page-not-found/page-not-found.scss'
import { ASButton } from "../../shared/as-button/as-button";

const UnauthorizedResource = (props) => {

    return (
        <div className="not-found-container">
            <div className="not-found-body">
                <h1 className="oops-heading">Oops!</h1>
                <h1>Unauthorized Access</h1>
                <p>You do not have access to {props.unauthorizedResourceError}</p>
                <div className="unauthorized-resource-button-div">
                    <ASButton primary={true} onClick={() => props.handleGoToHome()}>Go to Dashboard</ASButton>
                </div>
            </div>
        </div>
    );
};

export default UnauthorizedResource;
