import React, { Fragment } from 'react'
import { Dialog, Backdrop, ButtonGroup, Button } from "@mui/material";
import "./column-selection-popup.scss"
import ColumnSelectionHeader from './column-selection-header';
import ColumnSelectionList from './column-selection-list';
import AgGridTable from '../../common/ag-grid/ag-grid';
import { ASButton } from '../../../shared/as-button/as-button';
import CircularProgress from '@mui/material/CircularProgress';
import { AppEnum, ParameterTypeValue } from '../../../constants/app-enum';
import { SearchParameterController } from '../../../controllers/asheetPlugin/search-parameter.js/search-parameters';
import { DateParseVariable } from "../../../constants/date-parse-variable";

export default function ColumnSelectionPopup(props) {

    const { columnsList, rowData, handleOnChangeSelectColumn, sortModal, filterModal, openSortModal, openFilterModal, closeSortModal, closeFilterModal,
        onChangeSortInputs, sortInput, addSort, sortData, setSortData, handleDeleteSort, handleClickSort, handleSave, executeData, filterData, setFilterData,
        preview, handleChangeObject, isLoading, isDialogOpen, state, setState, selectedConnection, handleObjectChange, filterConfig, onSearchHandler, filterColumnsList, collectionFilterColumns, 
        closeLimitModal, openLimitModal, limitModal,limitData, setLimitData, searchInputRef,searchValue, setColumnList, setIsColumnOrderChanged, 
        handleSearchOptionsButtonClick, selectedSearchButton, isSearchOptionsAvailable, searchOptionsParameters, setSearchOptionsParameters, executedData, isOpenInDialog, handleAuthorizeAgain, isAuthorizeAgain, showNativeDataVisualizer} = props;
        //const dateParser = new DateParseVariable(); 

        const parameters= () =>{
            return searchOptionsParameters?.map((parameter, index) => {
                const parameterTypes = Object.values(ParameterTypeValue());

                // if(parameter.parseAtClientSide && parameter.sysDataTypeId === AppEnum.DataTypeId.Date){
                //     parameter.parameterValue = dateParser.parseVariable(parameter.defaultValue);
                // }

                if (parameterTypes.includes(parameter.parameterTypeCD)) {
                    return (
                        <div>
                            <SearchParameterController parameter={parameter} 
                                                       index={index} 
                                                       state={state} 
                                                       setSearchOptionsParameters={setSearchOptionsParameters} 
                                                       searchOptionsParameters={searchOptionsParameters} isPopup={true}
                                                       setState={setState}/>
                        </div>
                    )
                }
        })}

        const returnPopupView = () => {
            return (
                !isLoading &&
                    <div className='columns-selection-container'>

                <ColumnSelectionHeader sortModal={sortModal}
                                       filterModal={filterModal}
                                       limitModal={limitModal}
                                       openSortModal={openSortModal}
                                       openFilterModal={openFilterModal}
                                       openLimitModal={openLimitModal}
                                       closeSortModal={closeSortModal}
                                       closeFilterModal={closeFilterModal}
                                       closeLimitModal={closeLimitModal}
                                       columnsList={columnsList}
                                       onChangeSortInputs={onChangeSortInputs}
                                       sortInput={sortInput}
                                       addSort={addSort}
                                       sortData={sortData}
                                       limitData={limitData}
                                       setLimitData={setLimitData}
                                       setSortData={setSortData}
                                       handleDeleteSort={handleDeleteSort}
                                       handleClickSort={handleClickSort}
                                       handleSave={handleSave}
                                       filterData={filterData}
                                       setFilterData={setFilterData}
                                       handleChangeObject={handleChangeObject}
                                       executeData={executeData}
                                       state={state}
                                       setState={setState}
                                       selectedConnection={selectedConnection}
                                       handleObjectChange={handleObjectChange}
                                       filterConfig={filterConfig}
                                       collectionFilterColumns={collectionFilterColumns}
                                       handleAuthorizeAgain={handleAuthorizeAgain}
                                       isAuthorizeAgain={isAuthorizeAgain}
                                       isOpenInDialog={isOpenInDialog}
                                       showNativeDataVisualizer={showNativeDataVisualizer}/>

                <div className={isOpenInDialog ? 'columns-selection-content-container' : 'columns-selection-content-container-notfordialog'}>
                    <div className='vertical-container'>
                       {isSearchOptionsAvailable && <div className='button-group-container'>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button  onClick={() => handleSearchOptionsButtonClick(0)} className={selectedSearchButton === 0 ? 'selected' : ''}>
                                    Search Options
                                </Button>
                                <Button  onClick={() => handleSearchOptionsButtonClick(1)} className={selectedSearchButton === 1 ? 'selected' : ''}>
                                    Columns
                                </Button>
                            </ButtonGroup>
                        </div>}
                      {(selectedSearchButton === 0 && isSearchOptionsAvailable) && 
                      <div className='search-option-container'> 
                        <div>{parameters()} </div>
                        <div className='search-option-refresh-button'><ASButton primary={true} onClick={() => executedData()}>REFRESH PREVIEW</ASButton></div>
                      </div> }
                      {(selectedSearchButton === 1 || !isSearchOptionsAvailable) && <ColumnSelectionList columnsList={columnsList} handleOnChangeSelectColumn={handleOnChangeSelectColumn} onSearchHandler={onSearchHandler} filterColumnsList={filterColumnsList} searchInputRef={searchInputRef} searchValue={searchValue}/>}
                      </div>
                    <div className='data-table-div'>
                        <div className='data-table-main-div'>
                            <AgGridTable rows={rowData} columns={columnsList} isColumnSelection={true} executeData={executeData} setState={setState} setColumnList={setColumnList} setIsColumnOrderChanged={setIsColumnOrderChanged} isColumnMoveable={showNativeDataVisualizer}/>
                            {preview && <div className='refresh-button-div'><ASButton primary={true} onClick={() => executeData(true)}>REFRESH PREVIEW</ASButton></div>}
                        </div>
                        <div className='row-number-div'>
                            <span className='row-count-div'>Total {rowData.length} rows</span>
                        </div>
                    </div>
                </div>
            </div>
            )
        } 

  return isOpenInDialog ? 
        <Dialog open={true} handleCloseDialog={() => console.log('Closed')} 
        fullWidth={true}
        maxWidth="xl"
        PaperProps={{ sx: { borderRadius: "10px", height:"90vh", overflowY:"unset"  } }}
        >
            {returnPopupView()}

            {!isDialogOpen && 
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>}
      </Dialog> : 
      <>
        {returnPopupView()}

        {!isDialogOpen && 
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                    open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>}
      </>
}
