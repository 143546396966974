export class CollectionParametersState {
    parameterName = "";
    sysDataTypeID = '';
    defaultValue = "";
    displayName = "";
    isFixed = false;
    description = "";
    listColumnValue = "";
    listColumnLabel = "";
    isMultiSelectList = false;
    dynamicListCollectionID = 0;
    fixedListVariableId = 0;
    parameterTypeCD = "";
    inputFieldTypeCD = "";
    sendAs = null;
    variableUId = null;
    isOptionalParameter = false;
    isSystemDefineVariable = false;
    parseAtClientSide = false;
    parameterValue = "";
    displayOrder = 0;
}

export class DataCollectionState {
    connectionList = [];
    categoryList = [];
    collectionTypeList = [];
    variableList = [];
    fieldTypeList = [];
    dynamicList = [];
    columnList = [];
    columnSchemaList = [];
    basicInfoError = null;
    // collectionTypeObjectError = null;
    // parameterError = null;
    columnSchemaError = null;
    testCollectionError = null;
    cloudCollectionError = null;
    nativeCollectionError = null;
    isTestingConnection = false;
    isStepCollectionParameter = false;
    uiLabel = '';
    connectionUId = "";
    groupName = "";
    childGroupName = "";
    name = "";
    sysCollectionTypeId = '';
    sysConnectionTypeId = '';
    sysCollectionSubTypeId = '';
    sourceName = "";
    sourceNamePart2 = "";
    sourceNamePart4 = "";
    categoryUId = "";
    columnSchema = "";
    isCollectionAsDataSource = false;
    hasPrintableCollection = false;
    collectionParameters = [];
    collectionColumns = [];
    displayFormatList = [];
    sortedColumnList = [];
    testedDataList = [];
    testedDataColumnKeys = [];
    testedDataTransformedList = [];
    testedDataTransformedColumneKeys = [];
    prevStepRef = null;
    prevCollection = null;
    rESTRequestMethod = null;
    uId = "";
    restBody = null;
    restSendAsCD = null;
    restDataTransformationScript = null;
    templateCollectionId = null;
    collectionColumnSchema = null;
    cloudCollectionObjects = [];
    isFilterSupported = false;
    isSortSupported = false;
    areAllFieldsFilterable = false;
    areAllFieldsSortable = false;
    isPagingSupported = false;
    columnApiEndpoint = "";
    variableId = null;
    collectionFilterColumns = [];
    returnRawData = false;
    restRequestMethod=null;
}

export class DataCollectionModal {
    connectionUId = "";
    groupName = "";
    childGroupName = "";
    name = "";
    sysCollectionTypeId = '';
    sysConnectionTypeId = '';
    sysCollectionSubTypeId = '';
    sourceName = "";
    sourceNamePart2 = "";
    sourceNamePart4 = "";
    categoryUId = "";
    columnSchema = "";
    isCollectionAsDataSource = false;
    hasPrintableCollection = false;
    collectionParameters = [];
    collectionColumns = [];
    uId = "";
    restRequestMethod = null;
    restBody = null;
    restSendAsCD = null;
    restDataTransformationScript = null;
    templateCollectionId = null;
    variable = null;
    isFilterSupported = false;
    isSortSupported = false;
    isPagingSupported = false;
    areAllFieldsFilterable = false;
    areAllFieldsSortable = false;
    columnApiEndpoint = "";
    columnSchemaList = [];
    variableId = null;
    collectionFilterColumns = [];
    returnRawData = false;
}

export class CollectionFilterColumn {
    collectionId = null;
    uId = "";
    columnName = "";
    hasMultiSelection = false;
    operators = "";
    variableUId = "";
    variableName = "";
}


