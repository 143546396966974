import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Header } from "../../shared/header/header";
import "./layout.scss";
import { PageContent } from "./page-content/page-content";
import { SideMenu } from "../../components/side-menu/side-menu";
import jwt from 'jwt-decode'
import { useNavigate } from "react-router-dom";
import { useASContext } from "../context-api/as-context";
import { getRefreshToken, getToken } from "../../shared/local-storage-handler/local-storage-handler";
import { getSubscription } from "../../services/checkout-service";
import { AppEnum } from "../../constants/app-enum";

export function Layout() {

    const navigate = useNavigate();

    // states
    const [openDrawer, setOpenDrawer] = useState(false);
    const [userName, setUserName] = useState(null);
    const [tenants, setTenants] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [tenantAnchorEl, setTenantAnchorEl] = useState(null);
    const [isIndividualUser,setIsIndividualUser] = useState(true);
    const [savedOrganization,setSavedOrganization] = useState(0);
    const [refresh_token,setRefreshToken] = useState("");
    const [licenseTypeUId,setLicenseTypeUId] = useState(AppEnum.LicenseTypeUIds.FREE);


    const asContext = useASContext();

    useEffect(() => {
        getSubscription().then((res) => {          
            if (res && res.data) {
                setLicenseTypeUId(res.data[0].licenseTypeUId); 
                asContext?.subscriptionValidation.setIsSubscriptionExist(res.data[0].asTenantUId !== null && res.data[0].noOfUsers > 0);              
            }
        });
    },[])

    useEffect(() => {
        getUserName(false);
  
    }, [savedOrganization]);

     
    const [toShowDropAndPopUp,settoShowDropAndPopUp] = useState(false);

    useEffect(() => {
            if(tenants?.length){
                settoShowDropAndPopUp(((isIndividualUser && tenants?.length > 1)|| 
                (tenants.length > 1) ||
                (!isIndividualUser && tenants?.length == 1 && licenseTypeUId !== AppEnum.LicenseTypeUIds.FREE)));
            }     
    },[tenants?.length,licenseTypeUId]);

    // it decodes token and gets the user name to display name
    const getUserName = (tenant) => {

        let token = getToken();
        let refreshtoken = getRefreshToken();
        setRefreshToken(refreshtoken);

        if (token) {

            let decodedToken = jwt(token);
            setUserName(decodedToken?.fullname);
            setTenantsList(decodedToken, tenant);
            if(decodedToken?.isIndividual === "False"){
                setIsIndividualUser(false);
            }
        }
    }

    // at first time it will set the tenant menu list and the first option as default tenant name
    // once any other tenant will be choosen then that tenant will be reflected in the whole app
    const setTenantsList = (decodedToken, tenant) => {

        let tenantList = JSON.parse(decodedToken?.tenants);

        if (!asContext?.tenants?.selectedTenant || tenants?.length === 0) {

            if (!tenant && !asContext?.tenants?.selectedTenant) {

                // default tenant name
                asContext?.tenants?.setSelectedTenant(tenantList[0]);
            }
        }
        
        const aSTenantId = sessionStorage.getItem('ASTenantId') || localStorage.getItem('ASTenantId');
        // aSTenantId = localStorage.getItem('ASTenantId');
        if (aSTenantId){
            const selectedTenant = tenantList.find(item => item.ASTenantId === aSTenantId);
            if (selectedTenant) {
                asContext?.tenants?.setSelectedTenant(selectedTenant);
              }
        }
        setTenants(tenantList);
    }

    // it route to the login page
    const routeToLoginPage = () => {

        navigate('/login');
    }

    // it opens the tenants menu on mouse hover
    const openTenantMenu = (event) => {
        
        setIsMenuOpen(true);
        setTenantAnchorEl(event.currentTarget);
    }

    // it closes the tenants menu on mouse hover
    const closeTenantMenu = () => {

        setIsMenuOpen(false);
        setTenantAnchorEl(null);
    }


    return (
        <div className="layout-container">
            <Box sx={{ display: "flex" }}>
               
                <Header
                    openDrawer={openDrawer}
                    userName={userName}
                    tenants={tenants}
                    tenantName={asContext?.tenants?.selectedTenant?.Name}
                    routeToLoginPage={routeToLoginPage}
                    getUserName={getUserName}
                    isMenuOpen={isMenuOpen}
                    openTenantMenu={openTenantMenu}
                    tenantAnchorEl={tenantAnchorEl}
                    closeTenantMenu={closeTenantMenu}
                    isIndividualUser={isIndividualUser}
                    setSavedOrganization={setSavedOrganization}
                    refresh_token={refresh_token}
                    licenseTypeUId={licenseTypeUId}
                    toShowDropAndPopUp={toShowDropAndPopUp}
                />

                <SideMenu openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

                <Box
                    sx={{
                        marginTop: "80px",
                        position: "relative",
                        padding: "0 24px",
                        marginBottom: "20px",
                        width: "100%",
                    }}
                >

                    <PageContent />

                </Box>

            </Box>
        </div>
    );
}
