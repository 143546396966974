import { FormControlLabel, InputAdornment, MenuItem, Paper } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import Checkbox from '@mui/material/Checkbox';
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import "./basic-information.scss";
import { useParams } from "react-router-dom";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { InputConstants } from "../../../constants/input-constant";

const BasicInformation = (props) => {

    const { collectionUId: collectionUId } = useParams();

    const { connectionList, categoryList, childGroupName, groupName, name,
        connectionUId, categoryUId, isCollectionAsDataSource, basicInfoError, sourceNamePart2 } = props.state;

    const filterOptions = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.groupName, });

    const filterOptionsSubGroup = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.subGroupName, });

    return (
        <div ref={props.prevStepRef}>
            <Paper
                sx={{
                    minHeight: "100px",
                    width: "750px",
                    padding: "20px",
                }}
            >
                {
                    basicInfoError &&
                    <div className="collection-error-div">
                        <MessageToaster errorMessage={basicInfoError} />
                    </div>
                }
               

                <div className="basic-info-container">

                    {props?.isCloudStep && <ASTextField
                        className="role-text-field"
                        label="Data Connection"
                        key="Select a Data Connection"
                        variant="outlined"
                        SelectProps={{
                            MenuProps: {
                                className: 'basic-info-select', sx: selectedMenuItemBackgroundColor
                            }
                        }}
                        InputProps={{
                            startAdornment: props?.selectedConnection ? (
                                <InputAdornment position="start">
                                    <img
                                        src={`/assets/${props?.selectedConnection?.connectionTypeIconImage}`}
                                        alt={props?.selectedConnection?.connectionTypeIconImage}
                                        style={{ width: 20, height: 20 }}
                                    />
                                </InputAdornment>
                            ) : null,
                        }}
                        select={true}
                        onChange={(event) =>
                            props.onSelectConnectionHandler(event.target.value)
                        }
                        disabled={collectionUId ? true : false}
                        value={connectionUId}
                    >
                        {connectionList?.map((connection) => (
                            <MenuItem
                                key={connection.uId}
                                value={connection.uId}
                                //  value={JSON.stringify({
                                //     uId: connection.uId,
                                //     sysConnectionTypeId: connection.sysConnectionTypeId,
                                // })}
                                name={connection?.name}
                            >
                                {connection?.name}
                            </MenuItem>
                        ))}
                    </ASTextField>}

                    <ASTextField
                        className="text-field"
                        label="Data Collection Name"
                        variant="outlined"
                        onChange={props.onChangeDataCollectionNameHandler}
                        value={name}
                    />

                    {props?.isTemplateCompany && (
                        <ASTextField
                        className="text-field"
                        label="Data Entity Name"
                        variant="outlined"
                        onChange={props.onChangeSourceNamePart2NameHandler}
                        value={sourceNamePart2}
                    />
                    )}


                    <Stack spacing={2}>
                        <Autocomplete
                            id="groupname"
                            freeSolo
                            filterOptions={filterOptions}
                            className="text-field"
                            options={props.groups.map((option, index) => ({
                                groupName: option.groupName,
                                index: index
                            }))}
                            getOptionLabel={(option) => option['groupName'] || option}
                            renderOption={(props, option) => {
                                return (
                                    <MenuItem
                                        {...props}
                                        key={`option ${option.index}`}
                                        className={
                                            groupName === option.groupName
                                                ? "selected-opt"
                                                : "autocomplete-option"
                                        }
                                    >
                                        {option.groupName}
                                    </MenuItem>
                                );
                            }}
                            onChange={(event, group) => {
                                props.onSelectGroupNameHandler(group);
                            }}
                            value={groupName}
                            // PopperComponent={StyledAutocompletePopper}
                            // noOptionsText={null}
                            renderInput={(params) => (
                                <ASTextField
                                    {...params}
                                    onChange={(event) => {
                                        props.onChangeGroupNameHandler(event);
                                    }}
                                    label="Group Name"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: groupName && params.InputProps.endAdornment,
                                    }}
                                />
                            )}
                        />
                    </Stack>

                    {/* <ASTextField
                        className="text-field"
                        label="Sub Group Name"
                        variant="outlined"
                        onChange={props.onChangeSubGroupNameHandler}
                        value={childGroupName}
                    /> */}

                    {/* <ASTextField
                        className="text-field"
                        label="Sub Group Name"
                        variant="outlined"
                        SelectProps={{
                            MenuProps: {
                                className: 'basic-info-select', sx: selectedMenuItemBackgroundColor
                            }
                        }}
                        select={true}
                        onChange={(event) =>
                            props.onSelectSubGroupHandler(event.target.value)
                        }
                        value={childGroupName}
                    >
                        {props.subGroups.map((subGroup,index) => (
                            <MenuItem
                                key={index}
                                value={subGroup.name}

                            >
                                {subGroup.name}
                            </MenuItem>
                        ))}
                    </ASTextField> */}

                    <Stack spacing={2}>
                        <Autocomplete
                            id="subgroupname"
                            freeSolo
                            filterOptions={filterOptionsSubGroup}
                            className="text-field"
                            options={props.subGroups.map((option, index) => ({
                                subGroupName: option.name,
                                index: index
                            }))}
                            getOptionLabel={(option) => option['subGroupName'] === "" ? "No Options" : (option['subGroupName'] || option)}
                            renderOption={(props, option) => {
                                return (
                                    <MenuItem
                                        {...props}
                                        key={`option ${option.index}`}
                                        className={
                                            childGroupName === option.name
                                                ? "selected-opt"
                                                : "autocomplete-option"
                                        }
                                    >
                                        {option.subGroupName}
                                    </MenuItem>
                                );
                            }}
                            onChange={(event, subGroup) => {
                                props.onSelectSubGroupNameHandler(subGroup);
                            }}
                            value={childGroupName}
                            renderInput={(params) => (
                                <ASTextField
                                    {...params}
                                    onChange={(event) => {
                                        props.onChangeSubGroupNameHandler(event);
                                    }}
                                    label="Sub Group Name"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: childGroupName && params.InputProps.endAdornment,
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    
                </div>

               {!props?.isCloudStep && <FormControlLabel
                    control={
                        <Checkbox
                        checked={isCollectionAsDataSource}
                        onChange={(event) => props.onCheckCollectionAsConnection(event)}
                        sx={muiCheckboxStyle}
                        disabled={props.isVariableUsedInCollection || !props.isTestedData}
                        />
                    }
                    className="collection-as-connection"
                    label="Use Collection as Dynamic List Source"
                    />}

                    {isCollectionAsDataSource ? (
                    <div>
                        <div className="dynamic-list-input-container">
                        <div className="columns-input" >
                            <ASTextField
                            sx={{minWidth:"300px"}}
                            className="text-field"
                            type={InputConstants.text}
                            fullWidth
                            label="Display Column"
                            variant="outlined"
                            value={props?.isDynamicListValues?.DisplayColumn}
                            onChange={(event) =>
                                props.handleOnChnageFilterInput(event,
                                "DisplayColumn"
                                )
                            }
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                className: "menu-role-div",
                                sx: selectedMenuItemBackgroundColor,
                                },
                            }}
                            >
                            {props?.state?.columnSchemaList?.filter(column => column.isSelected).map((column, index) => {
                                return (
                                <MenuItem
                                    value={column.columnName}
                                    key={`column-list ${index}`}
                                >
                                    {column.columnName}
                                </MenuItem>
                                );
                            })}
                            </ASTextField>
                        </div>

                        <div className="columns-input" >
                            <ASTextField
                            sx={{minWidth:"300px"}}
                            className="text-field"
                            type={InputConstants.text}
                            fullWidth
                            label="Value Column"
                            variant="outlined"
                            value={props?.isDynamicListValues?.ValueColumn}
                            onChange={(event) =>
                                props.handleOnChnageFilterInput(event,
                                "ValueColumn"
                                )
                            }
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                className: "menu-role-div",
                                sx: selectedMenuItemBackgroundColor,
                                },
                            }}
                            >
                            {props?.state?.columnSchemaList?.filter(variable => variable.isSelected).map((variable, index) => {
                                return (
                                <MenuItem
                                    value={variable.displayName}
                                    key={`var-list ${index}`}
                                    onClick={() => {
                                        const dataType = variable?.DataType || variable?.dataType;  
                                        props.handleDefaultValueChange("", "DefaultValue", dataType);
                                      }}
                                >
                                    {variable.displayName}
                                </MenuItem>
                                );
                            })}
                            </ASTextField>
                        </div>
                        </div>

                        <div style={{ width: "88%" }}>
                        <ASTextField
                            className="text-field"
                            type={InputConstants.text}
                            fullWidth
                            label="Default Value"
                            variant="outlined"
                            placeholder={props?.valueColumnDataType?.format ? `e.g. ${props.valueColumnDataType.format}` : ''}
                            value={props?.isDynamicListValues?.DefaultValue || ""}
                            onChange={(event) => {
                                props.handleDefaultValueChange(event, "DefaultValue");
                            }}

                            SelectProps={{
                                MenuProps: {
                                className: "menu-role-div",
                                sx: selectedMenuItemBackgroundColor,
                                },
                            }}
                            select={props?.isValueColumnDataTypeBoolean}
                        >
                            {props?.isValueColumnDataTypeBoolean &&
                                 ['none', 'true', 'false'].map((boolValue) => (
                                    <MenuItem 
                                        key={`default-list-${boolValue}`}
                                        value={boolValue === 'none' ? "" : boolValue}
                                    >
                                        {boolValue.charAt(0).toUpperCase() + boolValue.slice(1)}
                                    </MenuItem>
                                ))
                            }
                        </ASTextField>
                        
                        </div>
                        {!props?.isDynamicListValues?.isDefaultValueCorrect &&
                            <label className="invalid-field">Invalid {props?.valueColumnDataType?.dataType} Format</label>}
                    </div>
                    ) : (
                    ""
                    )
                    
                    }
                    {props?.isTemplateCompany && (
                        <div className="checkbox-container">
                            <ASTextField
                                className="text-field"
                                // type={InputConstants.text}
                                fullWidth
                                label="Column Api EndPoint"
                                variant="outlined"
                                onChange={(event) => props.onChangeColumnEndpointApiHandler(event)}
                                value={props?.state?.columnApiEndpoint}
                            />
                            
                            <FormControlLabel control={
                                <Checkbox checked={props?.state?.isFilterSupported}
                                onChange={(event) => props.onToggleFilterSupported(event)}
                                sx={muiCheckboxStyle}
                                />
                                }
                                label = "Is Filter Supported"
                            />
                            {props?.state?.isFilterSupported && (
                                <FormControlLabel  control={
                                    <Checkbox className="indented-checkbox" checked={props?.state?.areAllFieldsFilterable}
                                            onChange={(event) => props.onToggleAllFieldsFilterable(event)}
                                            sx={muiCheckboxStyle}
                                    />
                                }
                                label = "Are All Fields Filterable"
                                style={{marginLeft: '25px'}}
                                />
                            )}
                            
                            <FormControlLabel control={
                                <Checkbox checked = {props?.state?.isSortSupported}
                                onChange={(event) => props.onToggleSortSupported(event)}
                                sx={muiCheckboxStyle}
                                />}
                                label = "Is Sort Supported"
                            />
                            {props.state.isSortSupported && (
                                <FormControlLabel control={
                                    <Checkbox checked = {props?.state?.areAllFieldsSortable}
                                        onChange={(event) => props.onToggleAllFieldsSortable(event)}
                                        sx={muiCheckboxStyle}
                                    />
                                }
                                label = "Are All Fields Sortable"
                                style={{marginLeft : '25px'}}
                                />
                            )}
                            
                            <FormControlLabel control={
                                <Checkbox checked = {props?.state?.isPagingSupported}
                                onChange={(event) => props.onTogglePagingSupported(event)}
                                sx={muiCheckboxStyle}
                                />}
                                label = "Is Paging Supported"    
                            />
                        </div>
                        
                    )}

            </Paper>
        </div >
    );
};

export default BasicInformation;