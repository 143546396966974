import React, { Fragment } from "react";
import "./user-permission.scss";
import styles from "../../styles/_exports.module.scss";
import { ASButton } from "../../shared/as-button/as-button";
import { Link } from "react-router-dom";
import { Divider, InputAdornment, ListItem, ListItemText, MenuItem, Paper } from "@mui/material";
import { ConnectionPermissionSvg, CollectionPermissionSvg } from "../../constants/common-svgs";
import { ASTextField } from "../../shared/as-text-fields";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { selectedMenuItemBackgroundColor } from "../data-collections/collection-parameters/collection-parameters";
import CloseIcon from '@mui/icons-material/Close';
import { AppEnum } from "../../constants/app-enum";
import { ALL } from "../../controllers/data-collections/data-collections-controller";

// material ui checkbox style
export const muiCheckboxStyle = {
    color: styles.secondaryColor,
    '&.Mui-checked': {
        color: styles.checkboxColor,
    },
    '&.Mui-disabled': {
        color: 'grey',
    }
}

const UserPermission = (props) => {

    const { userInfo, userConnectionPermission, userCollectionPermission, userRoles, currentUserRole, selectedCollections,
        selectedConnections, searchedCollectionList, searchedConnectionList } = props.state;

    const { collectionTableHeaderRowCells, connectionTableHeaderRowCells, searchCollectionRef, searchConnectionRef, isDisabled, filteredData,
        selectedConnectionName, checkedAllStatusForColections, checkedAllStatusForConnections} = props;

    const newConnectionList = props.connectionMenuItems();

    return (
        <Fragment>
            <div className="permission-container">
                <div className="add-btn-div">

                    <div className="users-name-div">

                        <span>Edit User Permission</span>
                        <label>{userInfo?.userName}</label>

                    </div>
                </div>

                <div>
                    <Paper className="user-role-paper">
                        <ListItem>
                            <div className="user-role-row">
                                <div className="menu-role">
                                    <ASTextField
                                        className="role-text-field"
                                        label="User's Role"
                                        key="User's Role"
                                        variant="outlined"
                                        SelectProps={{ MenuProps: { className: 'menu-role-div', sx: selectedMenuItemBackgroundColor } }}
                                        select={true}
                                        value={currentUserRole}
                                        onChange={props.handleUserRoleChange}
                                        disabled={isDisabled}
                                    >
                                        {
                                            userRoles?.map((role, index) => {
                                                return (
                                                    <MenuItem key={index} value={role.id} >{role?.normalizedName}</MenuItem>
                                                )
                                            })
                                        }
                                    </ASTextField>
                                </div>
                                {props.userRole.isRoleChanged ?
                                    <div className="add-new-btn-user">

                                        <ASButton sx={{ padding: "10px 35px 10px 35px", marginLeft: "40px" }}
                                            onClick={() => props.handleSaveUpdatedUserRole(currentUserRole)}
                                            rounded={true}
                                            primary={true}
                                        >
                                            SAVE
                                        </ASButton>

                                    </div>
                                    : ''}
                                    {props?.userRole?.role !== AppEnum.UserRoleIds.CompanyAdmin && (
                                        <ASTextField
                                        className="connection-text-field"
                                        label="Filter by Connection"
                                        key="Connection"
                                        variant="outlined"
                                        SelectProps={{ MenuProps: { className: 'menu-connection-div', sx: selectedMenuItemBackgroundColor } }}
                                        select={true}
                                        value={selectedConnectionName} 
                                        onChange={props.handleConnectionFIlter} 
                                        >
                                        {
                                            newConnectionList?.map((connection, index) => (
                                                <MenuItem key={index} value={connection?.connectionName}>
                                                <span className={`datasource-type-span ${connection?.connectionName === ALL ? 'all-connection' : ''}`}>
                                                        {connection?.connectionName === ALL ? (
                                                            connection?.connectionName
                                                        ) : (
                                                            <>
                                                                <img alt="type-icon" src={`/assets/${connection?.iconImage}`} />
                                                                {connection?.connectionName}
                                                            </>
                                                        )}
                                                    </span>
                                                </MenuItem>
                                            ))
                                        }
                                        </ASTextField>
                                    )}
                               
                            </div>
                        </ListItem>
                    </Paper>
                </div>

                {currentUserRole === AppEnum.UserRoleIds.CompanyAdmin

                    ?

                    <div>
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2, boxShadow: "$table-box-shadow !important" }} >

                                <ListItem>
                                    <ListItemText>

                                        <div className="report-permission-header">

                                            <div className="report-svg">
                                                <span>{CollectionPermissionSvg}</span>

                                                <div className="report-label">
                                                    <span>Collection Permission</span>
                                                    <label>Company Admin has access to all Data Collections</label>
                                                </div>
                                            </div>
                                        </div>

                                    </ListItemText>
                                </ListItem>

                                <ListItem>
                                    <ListItemText>

                                        <div className="report-permission-header">

                                            <div className="report-svg">
                                                <span>{ConnectionPermissionSvg}</span>

                                                <div className="report-label">
                                                    <span>Connection Permission</span>
                                                    <label>Company Admin has access to all Connections</label>
                                                </div>
                                            </div>
                                        </div>

                                    </ListItemText>
                                </ListItem>

                            </Paper>
                        </Box>
                    </div>

                    :

                    <>
                        <div>
                            <Box sx={{ width: '100%' }}>
                                <Paper sx={{ width: '100%', mb: 2 }} className='report-table-paper'>
                                    <ListItem>
                                        <ListItemText>

                                            <div className="report-permission-header">

                                                <div className="report-svg">
                                                    <span>{CollectionPermissionSvg}</span>

                                                    <div className="report-label">
                                                        <span>Collection Permission</span>
                                                        <label>Select the collections that user has access</label>
                                                    </div>
                                                </div>

                                                <div className="search-collection-input">
                                                    <ASTextField
                                                        className="text-field"
                                                        fullWidth
                                                        label="Search"
                                                        key="Search"
                                                        variant="outlined"
                                                        onChange={(event) => {
                                                            setTimeout(() => {
                                                                props.onChangeSearchCollectionHandler(event.target.value);
                                                            }, 100)
                                                        }}
                                                        inputRef={searchCollectionRef}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <CloseIcon className="clear-icon" onClick={() => props.onChangeSearchCollectionHandler(null)} />
                                                            </InputAdornment>,
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </ListItemText>
                                    </ListItem>

                                    <Divider />

                                    <TableContainer>

                                        <Table
                                            sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            size='medium'
                                        >
                                            <TableHead>

                                                <TableRow>

                                                    <TableCell padding="checkbox">

                                                        <Checkbox
                                                            color="primary"
                                                            checked={checkedAllStatusForColections}
                                                            onChange={(event) => props.onCheckAllCollectionHandler(event)}
                                                            sx={muiCheckboxStyle}
                                                            disabled={isDisabled}
                                                        />

                                                    </TableCell>

                                                    {
                                                        collectionTableHeaderRowCells.map((headCell, index) => {

                                                            return (
                                                                <TableCell
                                                                    key={headCell.id}
                                                                    align='left'
                                                                    className={index === 0 ? "name-cell" : ''}
                                                                    id={`enhanced-table-checkbox-${index}`}
                                                                    scope="row"
                                                                    padding={index === 0 ? "none" : 'normal'}
                                                                >
                                                                    {headCell.label}
                                                                </TableCell>)
                                                        })
                                                    }
                                                </TableRow>

                                            </TableHead>

                                            <TableBody>
                                                {
                                                    (
                                                        filteredData?.collections?.length > 0 ? filteredData.collections : 
                                                            (props.searchCollectionRef?.current?.value ? 
                                                            searchedCollectionList : userCollectionPermission)
                                                    )
                                                    .sort((a, b) => a.connectionName.localeCompare(b.connectionName))
                                                    .map((collection, index) => {

                                                        return (
                                                            <TableRow
                                                                hover
                                                                onClick={() => props.saveUserCollectionPermission(!collection?.hasPermission, collection)}
                                                                role="checkbox"
                                                                aria-checked={collection?.hasPermission}
                                                                tabIndex={-1}
                                                                key={index}
                                                                selected={collection?.hasPermission}
                                                            >
                                                                <TableCell>
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={collection?.hasPermission}
                                                                        sx={muiCheckboxStyle}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell
                                                                    className="name-cell"
                                                                    id={`reportCheckbox${index}`}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                     <span className="datasource-type-span">
                                                                        <img alt="type-icon" src={`/assets/${collection?.iconImage}`} />
                                                                        {collection?.connectionName}
                                                                        </span>
                                                                
                                                                </TableCell>

                                                                <TableCell align="left">{collection?.collectionName}</TableCell>

                                                                <TableCell align="left">{collection?.groupName}</TableCell>

                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </div>

                        {/* data source div */}

                        {currentUserRole === AppEnum.UserRoleIds.CollectionUser ? '' :
                            <div>
                                <Box sx={{ width: '100%' }}>
                                    <Paper sx={{ width: '100%', mb: 2 }} className='report-table-paper'>
                                        <ListItem>
                                            <ListItemText>

                                                <div className="report-permission-header">

                                                    <div className="report-svg">
                                                        <span>{ConnectionPermissionSvg}</span>

                                                        <div className="report-label">
                                                            <span>Connection Permission</span>
                                                            <label>Select the connections that user has access</label>
                                                        </div>
                                                    </div>
                                                    <div className="search-collection-input">
                                                        <ASTextField
                                                            className="text-field"
                                                            fullWidth
                                                            label="Search"
                                                            key="Search"
                                                            variant="outlined"
                                                            onChange={(event) => {
                                                                setTimeout(() => {
                                                                    props.onChangeSearchConnectionHandler(event.target.value);
                                                                }, 100)
                                                            }}
                                                            inputRef={searchConnectionRef}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <CloseIcon className="clear-icon" onClick={() => props.onChangeSearchConnectionHandler(null)} />
                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </ListItemText>

                                        </ListItem>

                                        <Divider />

                                        <TableContainer>

                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size='medium'
                                            >
                                                <TableHead>
                                                    <TableRow>

                                                        <TableCell padding="checkbox">

                                                            <Checkbox
                                                                color="primary"
                                                                checked={checkedAllStatusForConnections}
                                                                onChange={(event) => props.onCheckAllConnectionHandler(event)}
                                                                sx={muiCheckboxStyle}
                                                                disabled={isDisabled}
                                                            />

                                                        </TableCell>

                                                        {
                                                            connectionTableHeaderRowCells.map((headCell, index) => {

                                                                return (
                                                                    <TableCell
                                                                        key={headCell.id}
                                                                        align='left'
                                                                        className={index === 0 ? "name-cell" : ''}
                                                                        id={`connectionCheckbox${index}`}
                                                                        scope="row"
                                                                        padding={index === 0 ? "none" : 'normal'}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableCell>)
                                                            })
                                                        }

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        (props.searchConnectionRef?.current?.value ? searchedConnectionList : userConnectionPermission)
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((connection, index) => {

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    onClick={() => props.saveUserConnectionPermission(!connection?.hasPermission, connection)}
                                                                    role="checkbox"
                                                                    aria-checked={connection?.hasPermission}
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    selected={connection?.hasPermission}
                                                                >
                                                                    <TableCell >

                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={connection?.hasPermission}
                                                                            sx={muiCheckboxStyle}
                                                                            disabled={isDisabled}
                                                                        />

                                                                    </TableCell>

                                                                    <TableCell
                                                                        className="name-cell"
                                                                        id={`connectionCheckbox${index}`}
                                                                        scope="row"
                                                                        padding="none"
                                                                    >
                                                                        {connection?.name}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        <span className="datasource-type-span">

                                                                            <img alt="type-icon" src={`/assets/${connection?.iconImage}`} />
                                                                            {connection?.typeName}</span>
                                                                    </TableCell>

                                                                </TableRow>
                                                            );
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Box>
                            </div>
                        }
                    </>
                }
            </div>
        </Fragment>
    )

}

export default UserPermission;