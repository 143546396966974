import CustomNavigator from '../controllers/common/custom-navigator/custom-navigator';
import { toast } from 'react-toastify';
import { refreshTokenHandler } from './axios.interceptor';

// it returns the error status code or the found errorMessage to the interceptor
export const getErrorMessageOrStatusMessage = (error) => {

    let responseData = error?.response?.data;
    let status = error?.response?.status;
    let finalResponse = { hasError: true, errorMessage: '' }

    if (!error.response) {
        finalResponse.errorMessage = 'No internet or bad gateway';
        return finalResponse;
    }
    // Internal server error
    if (status === 500) {
        if (error.response?.data) {
            finalResponse.errorMessage = error.response?.data?.message;
            return finalResponse;
        }
    }
    else if (status === 405) {
        finalResponse.errorMessage = 'Method not allowed';
        return finalResponse;
    }
    else if (status === 408) {
        finalResponse.errorMessage = 'Request Timeout';
        return finalResponse;
    }
    else if (status === 415) {
        finalResponse.errorMessage = 'Unsupported media type';
        return finalResponse;
    }
    else if (status === 502) {
        finalResponse.errorMessage = 'Bad Gateway';
        return finalResponse;
    }
    else if (status === 503) {
        finalResponse.errorMessage = 'Service Unavailable';
        return finalResponse;
    }
    else if (status === 504) {
        finalResponse.errorMessage = 'Gateway Timeout';
        return finalResponse;
    }
    else
        // bad request
        if (status === 400) {

            if (responseData?.error_description) {
                finalResponse.errorMessage = responseData?.error_description;
                return finalResponse;
            }
            else if (responseData?.results?.length > 0) {

                let errorList = responseData?.results?.filter(i => i.validationErrors?.length > 0);
                finalResponse.errorMessage = errorList[0]?.validationErrors[0]?.errorMessage;

                return finalResponse;
            }
            else if (responseData?.validationErrors?.length > 0) {
                finalResponse.errorMessage = responseData?.validationErrors[0]?.errorMessage;
                return finalResponse;
            }
            else if (responseData?.validationErrors?.length === 0) {
                finalResponse.errorMessage = responseData?.errorMessage ? responseData?.errorMessage : responseData?.error;
                return finalResponse;
            }
            else if (responseData?.smsSendingError) {
                finalResponse.errorMessage = responseData?.smsSendingError;
                return finalResponse;
            }
            else if (responseData?.errors) {
                finalResponse.errorMessage = responseData?.title;
                return finalResponse;
            }
            else
                return finalResponse;
        }
        else
            // Not found
            if (status === 404) {
                if (error.response) {
                    finalResponse.errorMessage = error?.response?.statusText;
                    return finalResponse;
                }
            }
            else
                // Unauthorized error
                if (status === 401) {

                    localStorage.removeItem('token');
                    CustomNavigator.navigate('/login');
                } else if(status === 406) {
                    toast.info("Your role is changed, this window will be reloaded");
                    refreshTokenHandler();
                    setTimeout(()=>{
                        window.location.reload();
                    },[2000]);
                }
                else {
                    finalResponse.errorMessage = error?.response?.statusText;
                    return finalResponse;
                }
}