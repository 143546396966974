/** @format */

import React, { useState } from "react";
import { ASButton } from "../../../../shared/as-button/as-button";
import "./filterPopup.scss";
import InputLabel from "@mui/material/InputLabel";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import {
  conditionarr,
  sortArr,
} from "../../../../controllers/data-collections/column-selection/filter-popup/filter-sort-array";
import { DateDataTypes, SubDateFields } from "./date-filter/date-filter";
import AddFilterForm from "./add-filter-form/addFilterForm";
import { AppEnum } from "../../../../constants/app-enum";

export default function FilterPopup(props) {
  const {
    openAddCondition,
    openConditionModal,
    filterObject,
    filterData,
    handleOnChnageFilterInput,
    addCondition,
    deleteCondition,
    radioButtons,
    addNewCondition,
    setAddNewCondition,
    onRadioInputChange,
    closeFilterModal,
    columnsList,
    executeData,
    isSort,
    sortData,
    setOpenConditional,
    openConditional,
    handleAddGroup,
    isDisabled,
    handleAddCondtionCancel,
    setCurrentGroupIndex,
    currentGroupIndex,
    filterConfig,
    filterCondtions,
    getFilterConditionsByDataTypeName,
    state,
    setFilterObject,
    multiSelectedFields,
    handleMultiSelectedFields,
    handleDeleteChip,
    handleGroupOperatorChange,
    optionValues,
    handleOnChangeFilterValuesInput,
    getDateTimeFormate,
    handleFilterEditClick,
    editGroupIndex,
    editConditionIndex,
    handleEditCondition,
    handleCancelEditFilter,
    handleAutoCompleteDropdownInputChange
  } = props;

  var filterFields = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state.templateCollectionId)?.areAllFieldsFilterable
    // ? columnsList?.filter((i) => (i.isSelected && !i.isDefault) || i.isFilterable)
    ? columnsList?.filter((i) => i.isSelected)
    : columnsList?.filter((i) => i.isFilterable && i.isSelected);

  var data = isSort ? sortData : filterData;
  var conditionOrArr = isSort ? sortArr : filterCondtions;
  
console.log(filterObject,"filterObject")
  // const flattenedData = data?.flat();
  const totalCount = data?.length;
  const isAddGroupDisabled = filterData?.filter(group => group?.filters?.length > 0)?.length >= filterConfig?.numberOfFilterGroupAllowed;
  var isEmptyConditionsApplied = (filterObject?.condition == AppEnum.FilterConditions.HasProperty || filterObject?.condition == AppEnum.FilterConditions.NotHasProperty)

  return (
    <>
      {(openConditional || data?.length != 0) && (
        <div className="add-filter-modal">
          <div className="filter-item-container" style={{ overflowY: totalCount >= 3 ? "scroll" : "unset" }}>
            {openAddCondition && (
              <div>
                {(data?.filter(group => group?.filters?.length > 0))?.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    {/* Group Items */}
                    <div className="filter-paper-div">
                      {group?.filters?.map((item, conditionIndex) => {

                        if (editGroupIndex === groupIndex && editConditionIndex === conditionIndex) {
                          // Render the AddFilterForm in place of the row being edited
                          return (
                            <AddFilterForm
                              key={conditionIndex}
                              filterObject={filterObject}
                              filterFields={filterFields}
                              handleOnChnageFilterInput={handleOnChnageFilterInput}
                              isSort={isSort}
                              conditionOrArr={conditionOrArr}
                              multiSelectedFields={multiSelectedFields}
                              handleMultiSelectedFields={handleMultiSelectedFields}
                              SubDateFields={SubDateFields}
                              setFilterObject={setFilterObject}
                              handleAddCondtionCancel={handleAddCondtionCancel}
                              addCondition={handleEditCondition}
                              isDisabled={isDisabled}
                              handleDeleteChip={handleDeleteChip}
                              optionValues={optionValues}
                              handleOnChangeFilterValuesInput={handleOnChangeFilterValuesInput}
                              filterCondtions={filterCondtions}
                              isEmptyConditionsApplied={isEmptyConditionsApplied}
                              handleAutoCompleteDropdownInputChange={handleAutoCompleteDropdownInputChange}
                              isEditingFilter={true}
                            />
                          );
                        }

                        let filterConditions = getFilterConditionsByDataTypeName(item?.field, false);
                        let isWithinCondition = item?.condition === "BETWEEN" && (item?.dateLabelValue == "EXACT_DATES" || item?.dataTypeName == "number");
                        return (
                          <div key={conditionIndex}>
                            <div className="fields-group-label"  onClick={() => handleFilterEditClick(groupIndex, conditionIndex)}>
                              <div className="field-label-div">
                                <InputLabel>Field</InputLabel>
                                <label className="fields-group-label-value">{item?.displayName}</label>
                              </div>
                              <div className="field-label-div">
                                <InputLabel>Condition</InputLabel>
                                <label className="fields-group-label-value">
                                  {isSort
                                    ? sortArr.find(i => i?.value === item?.value)?.name
                                    : filterConditions?.find(i => i?.value === item?.condition)?.name}
                                </label>
                              </div>
                              { (<>{(!isSort && (item?.value != null)) && (
                                <div className="add-condition-value-div">
                                  <InputLabel>{isWithinCondition ? "From Value" : "Value"}</InputLabel>
                                  <label className="fields-group-label-value">{(DateDataTypes.includes(item?.dataTypeName) && isWithinCondition) ? getDateTimeFormate(item?.value) : item?.dateLabel ? item?.dateLabel : item?.valueLabel ? item?.valueLabel : item?.value}</label>
                                </div>
                              )}
                              {item?.highValue && isWithinCondition && (
                                <div className="add-condition-value-div">
                                  <InputLabel>To Value</InputLabel>
                                  <label className="fields-group-label-value">
                                    {DateDataTypes.includes(item?.dataTypeName) ? getDateTimeFormate(item?.highValue) : item?.highValue}
                                  </label>
                                </div>
                              )}</>)}
                              <div className="delete-icon-div">
                                <DeleteForeverOutlinedIcon onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteCondition(groupIndex, conditionIndex);
                                                            }} />
                              </div>
                            </div>

                            {!isSort && (
                              <div className="and-or-operator-div">
                                <div className="operator-div">
                                  {radioButtons?.length > 0 && (
                                    <ToggleButtonGroup
                                      value={
                                        radioButtons[groupIndex] &&
                                          radioButtons[groupIndex][conditionIndex] &&
                                          radioButtons[groupIndex][conditionIndex].defaultValue
                                          ? radioButtons[groupIndex][conditionIndex].defaultValue
                                          : (filterConfig.operatorAllowedInFilter.length === 1 ? filterConfig.operatorAllowedInFilter[0] : "or")
                                      }
                                      exclusive
                                      onChange={(_e, value) => onRadioInputChange(value, groupIndex, conditionIndex)}
                                      aria-label="And/Or"
                                    >
                                      <ToggleButton
                                        value="and"
                                        aria-label="And"
                                        disabled={!filterConfig.operatorAllowedInFilter.includes("and")}
                                        sx={{
                                          textTransform: "none !important",
                                          color: "#16365F",
                                          borderRadius: "6px",
                                          border: "0.2px solid #6B859E !important",
                                          width: "45px",
                                          padding: "0px",
                                          '&.Mui-selected': {
                                            backgroundColor: "#16365F !important",
                                            color: "white !important",
                                          },
                                        }}
                                      >
                                        And
                                      </ToggleButton>
                                      <ToggleButton
                                        value="or"
                                        aria-label="Or"
                                        disabled={!filterConfig.operatorAllowedInFilter.includes("or")}
                                        sx={{
                                          textTransform: "none !important",
                                          color: "#16365F",
                                          borderRadius: "6px",
                                          border: "0.2px solid #6B859E !important",
                                          borderLeft: "none !important",
                                          width: "45px",
                                          padding: "0px",
                                          '&.Mui-selected': {
                                            backgroundColor: "#16365F !important",
                                            color: "white !important",
                                          },
                                        }}
                                      >
                                        Or
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  )}
                                </div>

                                {conditionIndex === group?.filters?.length - 1 && (
                                  <Tooltip
                                  title={group?.filters?.length >= filterConfig?.numberOfFilterPerGroupAllowed
                                    ? filterConfig?.filterPerGroupExceeded
                                      ? filterConfig.filterPerGroupExceeded
                                      : `You cannot add more than ${filterConfig.numberOfFilterPerGroupAllowed} filters in a group`
                                    : ""}
                                    disableHoverListener={group?.filters?.length < filterConfig.numberOfFilterPerGroupAllowed}
                                    arrow
                                  >
                                    <span>
                                      <button
                                        className="cancel-link-button"
                                        onClick={() => {
                                          setAddNewCondition(true);
                                          setCurrentGroupIndex(groupIndex);
                                          handleCancelEditFilter();
                                        }}
                                        disabled={group?.filters?.length >= filterConfig?.numberOfFilterPerGroupAllowed}
                                      >
                                        + Add another condition
                                      </button>
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}

                      {(addNewCondition && currentGroupIndex == groupIndex) &&
                        <AddFilterForm filterObject={filterObject}
                          filterFields={filterFields}
                          handleOnChnageFilterInput={handleOnChnageFilterInput}
                          isSort={isSort}
                          conditionOrArr={conditionOrArr}
                          multiSelectedFields={multiSelectedFields}
                          handleMultiSelectedFields={handleMultiSelectedFields}
                          SubDateFields={SubDateFields}
                          setFilterObject={setFilterObject}
                          handleAddCondtionCancel={handleAddCondtionCancel}
                          addCondition={addCondition}
                          isDisabled={isDisabled}
                          handleDeleteChip={handleDeleteChip}
                          optionValues={optionValues} 
                          handleOnChangeFilterValuesInput={handleOnChangeFilterValuesInput}
                          filterCondtions={filterCondtions}
                          isEmptyConditionsApplied={isEmptyConditionsApplied}
                          handleAutoCompleteDropdownInputChange={handleAutoCompleteDropdownInputChange}/>}
                    </div>

                    {/* Add operator between groups */}
                    {groupIndex < data.length - 1 && (
                      <div className="group-operator-div">
                        <ToggleButtonGroup
                          value={
                            data[groupIndex]?.operator
                            ? data[groupIndex].operator
                              : (filterConfig.operatorAllowedInFilterGroup.length === 1 ? filterConfig.operatorAllowedInFilterGroup[0] : "or")
                          }
                          exclusive
                          onChange={(_e, value) => handleGroupOperatorChange(value, groupIndex)}
                          aria-label="And/Or Between Groups"
                        >
                          <ToggleButton value="and" aria-label="And"
                            disabled={!filterConfig.operatorAllowedInFilterGroup.includes("and")}
                            sx={{
                              textTransform: "none !important",
                              color: " #16365F",
                              borderRadius: "6px",
                              border: "0.2px solid #6B859E !important",
                              width: "45px",
                              padding: "0px",
                              '&.Mui-selected': {
                                backgroundColor: "#16365F !important",
                                color: "white !important",
                              }
                            }}>
                            And
                          </ToggleButton>
                          <ToggleButton value="or" aria-label="Or"
                            disabled={!filterConfig.operatorAllowedInFilterGroup.includes("or")}
                            sx={{
                              textTransform: "none !important",
                              color: " #16365F",
                              borderRadius: "6px",
                              border: "0.2px solid #6B859E !important",
                              borderLeft: "none !important",
                              width: "45px",
                              padding: "0px",
                              '&.Mui-selected': {
                                backgroundColor: "#16365F !important",
                                color: "white !important",
                              }
                            }}>
                            Or
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}


            {/* add form */}
            {(addNewCondition || data?.length == 0) && currentGroupIndex == null &&
              <div className="filter-paper-div">
                <AddFilterForm filterObject={filterObject}
                  filterFields={filterFields}
                  handleOnChnageFilterInput={handleOnChnageFilterInput}
                  isSort={isSort}
                  conditionOrArr={conditionOrArr}
                  multiSelectedFields={multiSelectedFields}
                  handleMultiSelectedFields={handleMultiSelectedFields}
                  SubDateFields={SubDateFields}
                  setFilterObject={setFilterObject}
                  handleAddCondtionCancel={handleAddCondtionCancel}
                  addCondition={addCondition}
                  isDisabled={isDisabled}
                  handleDeleteChip={handleDeleteChip}
                  optionValues={optionValues}
                  handleOnChangeFilterValuesInput={handleOnChangeFilterValuesInput}
                  filterCondtions={filterCondtions}
                  isEmptyConditionsApplied={isEmptyConditionsApplied}
                  handleAutoCompleteDropdownInputChange={handleAutoCompleteDropdownInputChange} />
              </div>
            }
          </div>


          {(data?.length > 0 && !addNewCondition) &&
            <div className="apply-changes-div">
              <div>
                <Tooltip
                  title={isAddGroupDisabled 
                         ? filterConfig?.filterGroupExceeded 
                         ?  filterConfig?.filterGroupExceeded 
                         : `You cannot add more than ${filterConfig.numberOfFilterGroupAllowed} groups` 
                         : ""}
                  disableHoverListener={!isAddGroupDisabled}
                  arrow
                >
                  <span>
                    <ASButton
                      variant="contained"
                      sx={{
                        backgroundColor: "#ffffff !important",
                        color: "black",
                        borderRadius: "7px !important",
                        border: "0.2px solid #6B859E !important",
                        ':hover': {
                          backgroundColor: "#ffffff !important"
                        },
                        textTransform: "none"
                      }}
                      onClick={() => handleAddGroup()}
                      disabled={isAddGroupDisabled}
                    >
                      Add Group
                    </ASButton>
                  </span>
                </Tooltip>
              </div>
              <div className="cancel-add-button-div">
                <div className="cancel-button-div">
                  <button
                    className="cancel-link-button"
                    onClick={() => closeFilterModal()}
                  >
                    Cancel
                  </button>
                </div>
                <div >
                  <ASButton
                    variant="contained"
                    sx={{ mr: 1, marginRight: "0px", textTransform: "none", borderRadius: "7px !important", }}
                    primary={true}
                    onClick={() => {
                      closeFilterModal();
                      executeData();
                    }}
                  >
                    Apply Changes
                  </ASButton>
                </div>
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
}
