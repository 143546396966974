import React, {
    createContext, useContext, useState
} from "react";

// creating hue app context
export const ASContext = createContext();

// single hue context hook which will contain all the context values for different global states
export function useASContext() {
    return useContext(ASContext);
}

export function ASContextProvider({ children }) {

    // global state for current choosen tenant name
    const [selectedTenant, setSelectedTenant] = useState(null);

    // it sets the hue spinner state if any data is being fetched from API 
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [connectionSelected, setConnectionSelected] = useState(false);
    const [renderComponent, setRenderComponent] = useState(null);

    //global state whether the selected company is template or not
    const [isTemplateCompany, setIsTemplateCompany] = useState(false);

    //global state whether tenant has bought subsription or not
    const [isSubscriptionExist,setIsSubscriptionExist] = useState(true);

    // whole hue app context value object
    let hueContextValue = {

        // global context state for tenant
        tenants: {
            selectedTenant: selectedTenant,
            setSelectedTenant: setSelectedTenant
        },
        
        // spinner
        spinner: {
            isDataLoading: isDataLoading,
            setIsDataLoading: setIsDataLoading
        },

        //plugin
        plugin:{
            connectionSelected: connectionSelected,
            setConnectionSelected: setConnectionSelected
        },

        asheetPlugin:{
            renderComponent: renderComponent,
            setRenderComponent: setRenderComponent
        },

        templateCompany:{
            isTemplateCompany:isTemplateCompany,
            setIsTemplateCompany:setIsTemplateCompany
        },

        subscriptionValidation:{
            isSubscriptionExist:isSubscriptionExist,
            setIsSubscriptionExist:setIsSubscriptionExist
        }
    }

    return (
        <div>
            <ASContext.Provider value={hueContextValue}>
                {children}
            </ASContext.Provider>
        </div>
    )
}