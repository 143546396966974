import { InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ASTextField } from "../as-text-fields";
import { SearchIconSvg } from "../../constants/common-svgs";


const SearchItemTextField = (props) => {

    const { isShrunk, onFocusSearchBoxHandler, onBlurSearchBoxHandler, onChangeSearchUserVariableHandler, searchInputRef } = props;

    return <div className="search-input">
        <ASTextField
            className={isShrunk ? 'label-text-field' : "collections-text-field"}
            fullWidth
            label="Search"
            key="Search"
            variant="outlined"
            onFocus={onFocusSearchBoxHandler}
            onBlur={onBlurSearchBoxHandler}
            InputLabelProps={{ shrink: isShrunk }}
            onChange={(event) => {
                onChangeSearchUserVariableHandler(event.target.value);
            }}
            inputRef={searchInputRef}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <CloseIcon className="clear-icon" onClick={() => { onChangeSearchUserVariableHandler(null) }} />
                </InputAdornment>,
                startAdornment: (
                    <InputAdornment position="start">
                        <span className='search-span-svg'>
                            {SearchIconSvg}</span>
                    </InputAdornment>
                ),
            }}
        />
    </div>
}

export default SearchItemTextField;