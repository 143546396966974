import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnauthorizedResource from "../../components/unauthorized-resource/unauthorized-resource";
import { useLocation } from "react-router-dom";
import { AppEnum } from "../../constants/app-enum";

const UnauthorizedResourceController = (props) => {

    const navigate = useNavigate();

    const location = useLocation();

    const [unauthorizedResourceError,setUnauthorizedResourceError] = useState("");

    useEffect(()=>{
        const previousPath = location.state?.from || '/';

        switch (true) {
            case previousPath.includes(AppEnum.WebPages.Variables):
              setUnauthorizedResourceError("add or edit variables.");
              break;
            case previousPath.includes(AppEnum.WebPages.AddCollection):
                setUnauthorizedResourceError("add new collection.");
                break;
            case previousPath.includes(AppEnum.WebPages.UpdateCollection):
                setUnauthorizedResourceError("update the collection.");
                break;
            case previousPath.includes(AppEnum.WebPages.AddConnection):
                setUnauthorizedResourceError("add the connection.");
                break;
            case previousPath.includes(AppEnum.WebPages.EditConnection):
                setUnauthorizedResourceError("edit the connection.");
                break;
            case previousPath.includes(AppEnum.WebPages.BookExpert):
                setUnauthorizedResourceError("schedule appointment with an expert.");
                break;
            case previousPath.includes(AppEnum.WebPages.UpdateCheckout):
                setUnauthorizedResourceError("upgrade or downgrade the subscription");
            default:
                setUnauthorizedResourceError("to provide permission to other users.");
                break;
          }
    },[]);

    const handleGoToHome = () => {
        navigate("/connections");
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <UnauthorizedResource handleGoToHome={handleGoToHome} handleGoBack={handleGoBack} unauthorizedResourceError={unauthorizedResourceError}/>
    );
};

export default UnauthorizedResourceController;
