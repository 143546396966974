import { Button } from "@mui/material";
import "./as-button.scss";

export function ASButton(props) {

    const { onClick, variant, children, disabled = false, sx, rounded, primary, buttonType = undefined, isPrimaryButtonUnrounded, isTextButton = false } = props;

    let classNames = "";
    if (rounded) {
        classNames += " button-rounded";
    }

    if (primary) {
        classNames += " button-primary";
    }
    else if (variant !== "text" && !isPrimaryButtonUnrounded) {
        classNames += "button-secondary";
    }

    if (disabled) {
        classNames = "";
    }

    if (isTextButton) {
        classNames += " cancel-button";
    }

    if (isPrimaryButtonUnrounded && !disabled) {
        classNames += "contained-primary-unrounded-button";
    }

    return (
        <Button
            disabled={disabled}
            className={buttonType || classNames}
            variant={variant}
            onClick={onClick}
            sx={sx}
        >
            {children}
        </Button>
    );
}
