import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DataCollectionsState } from '../../data-collections/data-collections-state'
import { ALL } from '../../data-collections/data-collections-controller'
import _ from 'lodash';
import SearchOrFilterVariablesHeader from '../../../components/connections/variables-list/search-or-filter-variables-header.jsx';


export const getVariableFilterFromStorage = () => {
    return localStorage.getItem("UserDefinedVariableFilter");
}

const SearchOrFilterVariablesHeaderController = (props) => {

    const { userDefinedState, setUserDefinedState, connectionList, templateCompany } = props;

    const [selectedConnectionValue, setSelectedConnectionValue] = useState(getVariableFilterFromStorage() || ALL);
    const [isShrunk, setIsShrunk] = useState(false);

    const searchInputRef = useRef('');

    const filterHeader = useMemo(() => [(new DataCollectionsState()).filterHeader[0]], []);

    useEffect(() => {
        const currentConnectionFilter = getVariableFilterFromStorage()

        if (connectionList?.length && currentConnectionFilter) {
            let selectedFilter = connectionList.find(i => i.uId === currentConnectionFilter);
            setSelectedConnectionFilter((currentConnectionFilter !== ALL && Boolean(selectedFilter)) ? selectedFilter.uId : ALL);
        }

    }, [connectionList])



    const onSelectedConnectionChange = (value) => {
        let currentConnectionFilter = getVariableFilterFromStorage();

        if (currentConnectionFilter === value)
            return

        setSelectedConnectionFilter(value)
    };

    const setSelectedConnectionFilter = (value) => {
        setSelectedConnectionValue(value);
        localStorage.setItem("UserDefinedVariableFilter", value);
    }


    const filterUserDefinedVariables = (selectedFilter) => {

        onSelectedConnectionChange(selectedFilter.uId);
        let filteredUsers = getFilteredUserDefinedVariables(selectedFilter);

        setUserDefinedState((prevState) => ({ ...prevState, filteredUsers }));
    }


    const getFilteredUserDefinedVariables = (selectedFilter) => {

        if (selectedFilter.uId === ALL)
            return userDefinedState.users

        let filteredUsers = templateCompany
            ? userDefinedState.users?.filter(list => (list.templateConnectionId === selectedFilter.id && list?.templateConnectionId !== null))
            : userDefinedState.users?.filter(list => (list.templateConnectionId === selectedFilter?.templateConnectionId && list?.templateConnectionId !== null));

        return filteredUsers;
    }


    const onFocusSearchBoxHandler = () => {
        setIsShrunk(true);
    }


    const onChangeSearchUserVariableHandler = (searchedKey) => {

        // to handle if user clears the input from close icon
        if (searchedKey === null)
            setIsShrunk(false);

        const searchKey = searchedKey?.toLowerCase().trim();

        let filteredUsers = [];
        if (searchKey) {
            filteredUsers = _.filter(userDefinedState.filteredUsers, (user) =>
                user?.name?.toLowerCase().trim().includes(searchKey))
        }
        else {
            searchInputRef.current.value = '';

            if (selectedConnectionValue === ALL)
                filteredUsers = userDefinedState.users
            else {
                let selectedFilter = connectionList.find(i => i.uId === selectedConnectionValue);
                filteredUsers = getFilteredUserDefinedVariables(selectedFilter);
            }
        }
        setUserDefinedState((prevState) => ({ ...prevState, filteredUsers }));
    }

    const onChangeSearchCollectionDebouncedHandler = _.debounce(onChangeSearchUserVariableHandler, 300)



    const onBlurSearchBoxHandler = (event) => {

        if (!event.target.value?.length)
            setIsShrunk(false);
    }

    const connectionMenuList = useMemo(() => {

        return [{ uId: ALL, name: ALL }, ...(
            _.uniqBy(connectionList, function (connection) {
                return connection?.name;
            })
            || [])];

    }, [connectionList])



    return (
        <SearchOrFilterVariablesHeader
            filterHeader={filterHeader}
            onSelectedConnectionChange={onSelectedConnectionChange}
            selectedConnectionValue={selectedConnectionValue}
            filterUserDefinedVariables={filterUserDefinedVariables}
            onFocusSearchBoxHandler={onFocusSearchBoxHandler}
            onBlurSearchBoxHandler={onBlurSearchBoxHandler}
            onChangeSearchUserVariableHandler={onChangeSearchCollectionDebouncedHandler}
            searchInputRef={searchInputRef}
            isShrunk={isShrunk}
            connectionMenuList={connectionMenuList}
        />
    )
}

export default SearchOrFilterVariablesHeaderController