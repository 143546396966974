import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsersList from "../../components/users-list/users-list";
import * as UserService from "../../services/user-service";
import { refreshTokenHandler } from "../../core/axios.interceptor";
import jwtDecode from "jwt-decode";
import { getRemainingBalance } from "../../services/license-service";
import { AppEnum } from "../../constants/app-enum";
import { useDispatch, useSelector } from "react-redux";
import { setRemainingFeatureBalance } from "../../store/slices/as-Slice";
import AddNewUserController from "./add-new-user-controller";
import { setToken } from "../../shared/local-storage-handler/local-storage-handler";
import { setSessionStorageItem } from "../../shared/session-storage-handler/session-storage-handler";
import jwt from 'jwt-decode'

class UsersListState {
  isUserMenuOpen = false;
  anchorEl = null;
  selectedRowIndex = null;
  users = [];
}
class InvitedUsersListState {
  isInvitedUser = false;
  anchorEl = null;
  selectedRowIndex = null;
  email = "";
  users = [];
}
const UsersListController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(new UsersListState());
  const [invitedUserState, setInvitedUserState] = useState(
    new InvitedUsersListState()
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loggedUserEmail, setLoggedUserEmail] = useState("");
  const [loggedUserRole, setLoggedUserRole] = useState("");
  const [userListRefresh, setUserListRefresh] = useState(0);
  const [isDialogOpen , setIsDialogOpen] = useState(false);
  const [leaveOrganization , setLeaveOrganization] = useState(false);
  const [companyOwner, setCompanyOwner] = useState(null);

  useEffect(() => {
    dispatch(setRemainingFeatureBalance(null));

    getUsersList();
    getInvitedUsersList();
    getLoggedEmail();
    getLoggedUserRole();
  }, [userListRefresh]);

  const remainingBalance = () =>{
    getRemainingBalance(AppEnum.FeatureType.COLLECTION_USERS_LIMIT).then((res) =>{
      if(res?.data?.featureCD != null){
        dispatch(setRemainingFeatureBalance(res.data.remainingFeatureUnit));
      }
    })
  }

  const handleCloseDialog =() =>{
      setIsDialogOpen(false)
  }

  const handleAddNewUser = () =>{
    setIsDialogOpen(true)
  }
  
const getLoggedEmail=()=>{
  let accessToken = localStorage.getItem('token');
  const decodedToken = jwtDecode(accessToken);
  let emailId = decodedToken.username;
  setLoggedUserEmail(emailId);
}

const getLoggedUserRole = () => {
  let accessToken = localStorage.getItem('token');
  const decodedToken = jwtDecode(accessToken);
  let userRole = decodedToken.role;
  setLoggedUserRole(userRole);
}

  // it fetches all the users list
  const getUsersList = () => {
    UserService.getAllUsers().then((response) => {
      if (response?.data) {
        remainingBalance();
        getCompanyOwner(response?.data);
        setState((prevState) => {
          return { ...prevState, users: response.data };
        });
      }
    });
  };
  // it fetches all the invitedUsers list
  const getInvitedUsersList = () => {
    UserService.getInvitedAllUsers().then((response) => {
      if (response?.data) {
        remainingBalance();
        setInvitedUserState((prevState) => {
          return { ...prevState, users: response.data };
        });
      }
    });
  };

  // it opens the choosen user permission and passes the user data to the next screen
  const editUserPermission = (user) => {
    navigate("/users/user-permission", {
      state: user,
    });
  };
  //resend invite email
  const resendInvite = (index) => {
    const email = invitedUserState.users[index].email;
    UserService.resendInvite(email).then((response) => {
      if (response.data) {
        setInvitedUserState((prevState) => {
          return {
            ...prevState,
            isInvitedUser: false,
            anchorEl: null,
            selectedRowIndex: null,
          };
        });
      }
    });
  };

  const openConfirmationModal = (event, index) => {
    setDeleteIndex(index);
    setConfirmationModalOpen(true);
    setInvitedUserState((prevState) => {
      return {
        ...prevState,
        isInvitedUser: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };

  const closeConfirmationModal = () => {
    setDeleteIndex(null);
    setConfirmationModalOpen(false);
    setInvitedUserState((prevState) => {
      return {
        ...prevState,
        isInvitedUser: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };
  //cancle invite
  const cancelInvite = () => {
    const uId = invitedUserState.users[deleteIndex].uId;
    UserService.cancelInvite(uId).then((response) => {
      if (response.data) {
        getInvitedUsersList();
        remainingBalance();
        setInvitedUserState((prevState) => {
          return {
            ...prevState,
            isInvitedUser: false,
            anchorEl: null,
            selectedRowIndex: null,
          };
        });
      }
      closeConfirmationModal();
    });
  };
  // it opens or closes the user menu options (edit and delete)
  const openUserMenuOption = (event, isInvited, index) => {
    if (event) event.stopPropagation();
    if (isInvited) {
      setInvitedUserState((prevState) => {
        return {
          ...prevState,
          isInvitedUser: true,
          anchorEl: event.target,
          selectedRowIndex: index,
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isUserMenuOpen: true,
          anchorEl: event.target,
          selectedRowIndex: index,
        };
      });
    }
  };

  // it opens or closes the user menu options (edit and delete)
  const closeUserMenuOption = (event) => {
    if (event){
      event.stopPropagation();
    } 

    setState((prevState) => {
      return {
        ...prevState,
        isUserMenuOpen: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
    setInvitedUserState((prevState) => {
      return {
        ...prevState,
        isInvitedUser: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };

  const openDeleteConfirmationModal = (user) => {
    setSelectedUser(user);

    setIsConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setSelectedUser(null);
    setSelectedIndex(null);
    setIsConfirmationModalOpen(false);
    setState((prevState) => {
      return {
        ...prevState,
        isUserMenuOpen: false,
        anchorEl: null,
        selectedRowIndex: null,
      };
    });
  };

  const deleteSelectedUser = (event, user, label) => {
    if(event){
      event.stopPropagation();
    }
    setLeaveOrganization(label === "organization");
    closeUserMenuOption();
    openDeleteConfirmationModal(user);
  };

  const handleDeleteConfirmed =() => {
      const userId = selectedUser?.userId;
      UserService.deleteUser(userId).then(async (response) => {
        if (response.data) {
          getUsersList();
          setState((prevState) => ({
            ...prevState,
            isUserMenuOpen: false,
            anchorEl: null,
            selectedRowIndex: null,
          }));
          const isDeletedOwnerTenant = deletedInvitedOwnerTenant();
          await refreshTokenHandler()
          if (leaveOrganization && isDeletedOwnerTenant) {
            handleOrganizationTenant();
          }
          else {
            setUserListRefresh(Math.random());
          }
        }
      });
      closeDeleteConfirmationModal();
  };

  const handleOrganizationTenant = () => {
    let accessToken = localStorage.getItem('token');
    const decodedToken = jwtDecode(accessToken);
    let aSTenantUId = decodedToken.ASTenantUId;
    setToken("ASTenantId", aSTenantUId);
    setSessionStorageItem("ASTenantId", aSTenantUId);
    window.location.reload();
  }

  const deletedInvitedOwnerTenant = () => {
    let accessToken = localStorage.getItem('token');
    const decodedToken = jwtDecode(accessToken);
    const tenantList = JSON.parse(decodedToken?.tenants);
    if (tenantList?.length == 1) {
      if (decodedToken?.sub != tenantList[0]?.OwnerSub) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const getCompanyOwner = (userList) => {
    let token = localStorage.getItem("token");
    let decodedToken = jwt(token);
    if (decodedToken) {
      var tenants = JSON.parse(decodedToken?.tenants);
      let aSTenantId = localStorage.getItem("ASTenantId");
      let companyTenant = tenants?.find(t => t.ASTenantId == aSTenantId);
      if (companyTenant) {
        let companyOwner = userList?.find(u => u.userId == companyTenant?.OwnerSub);
        setCompanyOwner(companyOwner);
      }
  }
}

  return (
    <Fragment>
      <UsersList
        invitedUserState={invitedUserState}
        editUserPermission={editUserPermission}
        openUserMenuOption={openUserMenuOption}
        state={state}
        closeUserMenuOption={closeUserMenuOption}
        deleteSelectedUser={deleteSelectedUser}
        resendInvite={resendInvite}
        cancelInvite={cancelInvite}
        closeConfirmationModal={closeConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        confirmationModalOpen={confirmationModalOpen}
        deleteConfirmationModalOpen={deleteConfirmationModalOpen}
        closeDeleteConfirmationModal={closeDeleteConfirmationModal}
        handleDeleteConfirmed={handleDeleteConfirmed}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        loggedUserEmail={loggedUserEmail}
        loggedUserRole={loggedUserRole}
        handleAddNewUser={handleAddNewUser}
        leaveOrganization={leaveOrganization}
        companyOwner={companyOwner}
      />
      <AddNewUserController open={isDialogOpen} handleCloseDialog={handleCloseDialog} getInvitedUsersList={getInvitedUsersList}/>
    </Fragment>
  );
};

export default UsersListController;
