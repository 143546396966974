import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  styled,
  Typography,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import themeColor from "../../../styles/_exports.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { Paper } from "@mui/material";
import { ASButton } from "../../../shared/as-button/as-button";
import { CollectionFilterColumnForm } from "../../../controllers/data-collections/add-new-data-collection/collection-filter-column/collection-filter-column-controller";
import { ASTextField } from "../../../shared/as-text-fields";
import { InputConstants } from "../../../constants/input-constant";
import "./collection-filter-column.scss";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { tableBorderRight } from "../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { useParams } from "react-router-dom";
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import ExpandableView from "../../common/expandableView/expandableView";
import {useState} from "react";
import operatorsList from "../../common/operators/Operators";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { useSelector } from "react-redux";

// it changes the background color of the selected menu item
export const selectedMenuItemBackgroundColor = {
  "&& .Mui-selected": {
    backgroundColor: `${themeColor.primaryColor} !important`,
    color: `white !important`,
  },
};

// it changes the background color of the selected autocomplete popper
const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    fontSize: "13px !important",

    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: `${themeColor.primaryColor} !important`,
        color: `white !important`,
      },

      '&[data-focus="true"]': {
        backgroundColor: `${themeColor.primaryColor} !important`,
        color: `white !important`,
      },

      "&:hover": {
        backgroundColor: `${themeColor.avatarBackground}`,
        color: `${themeColor.secondaryColor}`,
      },
    },
  },

  [`&.${autocompleteClasses.popper}`]: {
    marginTop: "-1em",
  },
}));

// it displays the Collection_Filter_Column table's header titles
export const columnFilterTableHead = (isReadOnly) => (
  <TableHead sx={{ minWidth: 750, borderTop: themeColor.borderBottom }}>
    <TableRow>
      <TableCell
        align="left"
        scope="row"
        component={"th"}
        sx={tableBorderRight}
      >
        Column Name
      </TableCell>

      <TableCell
        align="left"
        scope="row"
        component={"th"}
        sx={tableBorderRight}
      >
        Has Multi Selection
      </TableCell>

      {
        <TableCell
          align="left"
          scope="row"
          component={"th"}
          sx={tableBorderRight}
        >
          Operators
        </TableCell>
      }

      <TableCell
        align="left"
        scope="row"
        component={"th"}
        sx={tableBorderRight}
      >
        Variables
      </TableCell>

      {!isReadOnly && (
        <TableCell align="left" scope="row" component={"th"}></TableCell>
      )}
    </TableRow>
  </TableHead>
);

const CollectionFilterColumns = (props) => {
  const { collectionUId: collectionUId } = useParams();

  const {
    addNewCollectionFilterColumn,
    deleteCollectionColumnFilter,
    editCollectionFilterColumn,
    isAddDisable,
    isAddNewCollectionColumnFillter,
    state,
    isEditMode,
    setIsEditMode,
    prevStepRef,
    setCollectionFilterColumnState,
    collectionFilterColumnState,
    handleToggleHasMultiSelection,
    onHandleSelectVariableHandler,
    variables,
    handleMenuItemClick,
    setState,
    closeDialog
  } = props;
  const {collectionFilterColumns } = props.state;
  const showSendAs = props.state.sysCollectionTypeId == 7;
  const isExpandedView = useSelector((state) => state.asState.isExpandedView);

  return (
    <div className="w-1250">
      <Paper
        sx={{ minHeight: "100px", padding: "20px", paddingBottom: "0px" }}
        ref={prevStepRef}
      >
        <ExpandableView>
          <div className="add-collection-btn-div">
            <Typography
              sx={{ color: themeColor.text_2 }}
              variant="body1"
              display="block"
            >
              Filter Column
            </Typography>

            <IconButton
              disabled={isAddDisable || isEditMode}
              disableRipple
              sx={{ color: themeColor.primaryButton }}
              onClick={() => addNewCollectionFilterColumn()}
            >
              <AddCircleIcon sx={{ fontSize: "2rem" }} />

              <Typography
                sx={{ marginLeft: "5px", color: themeColor.text_2 }}
                variant="body1"
                display="block"
              >
                Add
              </Typography>
            </IconButton>
          </div>

          <div className="w-100 parameters-container">
            {
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  size="small"
                  className="coll-parameter-table"
                >
                  {columnFilterTableHead(false, showSendAs)}

                  <TableBody>
                    {collectionFilterColumns?.map((ele, index) => {
                      return !ele.edit ? (
                        <TableRow key={`collection-param ${index}`}>
                          <TableCell
                            align="left"
                            component={"td"}
                            sx={tableBorderRight}
                          >
                            {" "}
                            {ele?.columnName}{" "}
                          </TableCell>

                          <TableCell align="left" component={'td'} sx={tableBorderRight}>
                            {String(ele?.hasMultiSelection).charAt(0).toUpperCase() + String(ele?.hasMultiSelection).slice(1)}
                          </TableCell>

                          <TableCell align="left" component="td" sx={tableBorderRight}>
                            <Select
                              displayEmpty
                              value=""
                              renderValue={() => "Operators"}
                              disabled={ele?.operators?.length === 0}
                            >
                              {ele?.operators?.split(',').map((operator, idx) => (
                                <MenuItem key={idx} value={operator.trim()}>
                                  {operator.trim()}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>

                          <TableCell
                            align="left"
                            component={"td"}
                            sx={tableBorderRight}
                          >
                            {" "}
                            {ele?.variableName}{" "}
                          </TableCell>
                          
                          <TableCell align="left" component={"td"}>
                            <div className="edit-delete-btn-div">
                              <ASButton
                                sx={{ color: themeColor.linkColor }}
                                disabled={
                                  (isAddNewCollectionColumnFillter || isEditMode) && !ele.edit
                                }
                                variant="text"
                                onClick={() => editCollectionFilterColumn(index)}
                              >
                                EDIT
                              </ASButton>
                              |
                              <ASButton
                                sx={{
                                  color: themeColor.linkColor,
                                  padding: "6px 15px",
                                }}
                                variant="text"
                                onClick={() => deleteCollectionColumnFilter(index)}
                              >
                                DELETE
                              </ASButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            sx={{ borderBottom: "none" }}
                            key={index}
                          >
                            <div className="row-wrapper body">
                              <div className="form-wrapper">
                                <CollectionFilterColumnForm
                                  onChangeColumnNameHandler={props.onChangeColumnNameHandler}
                                  setIsAddNewCollectionColumnFilter={props.setIsAddNewCollectionColumnFilter}
                                  setCollectionFilterColumn={props.setCollectionFilterColumn}
                                  state={state}
                                  index={index}
                                  setIsEditMode={setIsEditMode}
                                  collectionFilterColumnState={collectionFilterColumnState}
                                  setCollectionFilterColumnState={setCollectionFilterColumnState}
                                  handleToggleHasMultiSelection={handleToggleHasMultiSelection}
                                  onHandleSelectVariableHandler={onHandleSelectVariableHandler}
                                  variables={variables}
                                  handleMenuItemClick={handleMenuItemClick}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {isAddNewCollectionColumnFillter && (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                          <div className="row-wrapper body">
                            <div className="form-wrapper">
                              <CollectionFilterColumnForm
                                onChangeColumnNameHandler={props.onChangeColumnNameHandler}
                                onChangeDisplayColumnHandler={props.onChangeDisplayColumnHandler}
                                setIsAddNewCollectionColumnFilter={props.setIsAddNewCollectionColumnFilter}
                                setCollectionFilterColumn={props.setCollectionFilterColumn}
                                state={state}
                                index={null}
                                setIsEditMode={setIsEditMode}
                                collectionFilterColumnState={collectionFilterColumnState}
                                setCollectionFilterColumnState={setCollectionFilterColumnState}
                                handleToggleHasMultiSelection={handleToggleHasMultiSelection}
                                onHandleSelectVariableHandler={onHandleSelectVariableHandler}
                                variables={variables}
                                handleMenuItemClick={handleMenuItemClick}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
           {isExpandedView && <MuiDialog
              isShowPopUp={state.isShowDeletePopUp}
              secondaryButtonAction={closeDialog}
              primaryButtonAction={deleteCollectionColumnFilter}
              closeDialog={closeDialog}
              secondaryButtonTitle="No"
              primaryButtonTitle="Yes"
              titleDescription="Are you sure you want to delete the collection filters?"
          />}
          </div>
        </ExpandableView>
      </Paper>
    </div>
  );
};

export default CollectionFilterColumns;

export const CollectionFilterColumnFormView = (props) => {
  const {
    isSaveEnable,
    parameterIndex,
    collectionFilterColumnState,
    variables,
  } = props;

  const { columnName, hasMultiSelection, variableUId, variableName, operators } = collectionFilterColumnState;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="width-75">
        <ASTextField
          className="text-field"
          type={InputConstants.text}
          fullWidth
          label="Column Name"
          variant="outlined"
          value={columnName}
          onChange={props.onChangeColumnNameHandler}
        ></ASTextField>

        <FormControlLabel
          control={
            <Checkbox
              checked={hasMultiSelection}
              onChange={(event) =>
                props.handleToggleHasMultiSelection(event)
              }
              sx={muiCheckboxStyle}
            />
          }
          label="Has Multi Selection"
        />

      <ASTextField
      className="text-field"
      type={InputConstants.text}
      fullWidth
      label="Operators*"
      variant="outlined"
      onMouseDown={(event) => event.preventDefault()}
      value={operators}
      select
      SelectProps={{
        MenuProps: {
          className: "menu-role-div",
        },
        renderValue: () => operators || 'Select Operators',
            open: open,
            onClose: () => setOpen(false),
            onOpen: () => setOpen(true),
      }}
    >
      {operatorsList.map((option) => (
        <MenuItem key={option.value} value={option.value}
                  onClick={(event) => props.handleMenuItemClick(event, option)}>
          <Checkbox
            checked={operators?.replace(/ /g, "").split(",").includes(option.value)}
            // onChange={(event) => props.onSelectHandleOperators(event)}
            value={option.value}
            sx={muiCheckboxStyle}
          />
          {option.label}
        </MenuItem>
      ))}
    </ASTextField>

    <ASTextField
          className="text-field"
          type={InputConstants.text}
          fullWidth
          label="Variables"
          variant="outlined"
          value={variableUId}
          onChange={(event) => props.onHandleSelectVariableHandler(event)}
          select={true}
          SelectProps={{
            MenuProps: {
              className: "menu-role-div",
              sx: selectedMenuItemBackgroundColor,
            },
            renderValue: () => variableName || "Select a variable"
          }}
        >
          {variables?.map((variable) => (
            <MenuItem key={variable.UId} value={variable}>
              {variable.name}
            </MenuItem>
          ))}
        </ASTextField>

        <div className="d-flex ok-btn-div">
          <div className="px-2 parameter-cancel-btn">
            <ASButton onClick={() => props.cancelAddingCollectionFilterColumn()}>
              CANCEL
            </ASButton>
          </div>

          <div
            className={
              !isSaveEnable
                ? "parameter-ok-button ok-btn"
                : "ok-btn collection-ok-btn"
            }
          >
            <ASButton
              onClick={() => props.addCollectionFilterColumn()}
              primary={true}
              disabled={!isSaveEnable}
            >
              {" "}
              {parameterIndex !== null ? "Update" : `OK`}{" "}
            </ASButton>
          </div>
        </div>
      </div>
    </>
  );
};
