import { Stack } from '@mui/material'
import React from 'react'
import { ASButton } from '../as-button/as-button';
import { Link } from "react-router-dom";
import SearchItemTextField from './search-item-textfield';



const TableMainHeader = (props) => {

    const { totalRows, tableTitle, hasAddButton, hasSearchField, onClick, onFocusSearchBoxHandler, onBlurSearchBoxHandler, onChangeSearchUserVariableHandler,
        searchInputRef, isShrunk, countClassName, parentClassName } = props;

    return (

        <div className={`add-new-variable-btn ${parentClassName}`}>

            <div className={`total-user-defined-variables ${countClassName}`}>
                <span>
                    Total <span>{totalRows}</span>
                </span>
                <label>{tableTitle}</label>
            </div>

            {
                hasAddButton && <div className="add-new-link">
                    <ASButton
                        sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                        onClick={() => onClick()}
                        rounded={true}
                        primary={true}
                    >
                        <Link
                            className="new-link"
                        >
                            <i className="plus-icon">+</i> ADD NEW
                        </Link>
                    </ASButton>
                </div>
            }

            {
                hasSearchField &&
                <SearchItemTextField
                    isShrunk={isShrunk}
                    onFocusSearchBoxHandler={onFocusSearchBoxHandler}
                    onBlurSearchBoxHandler={onBlurSearchBoxHandler}
                    onChangeSearchUserVariableHandler={onChangeSearchUserVariableHandler}
                    searchInputRef={searchInputRef}
                />
            }
        </div>

    )
}

export default TableMainHeader