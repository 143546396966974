import { Fragment, useEffect, useState } from "react";
import CollectionParameters, { CollectionParametersFormView } from "../../../../components/data-collections/collection-parameters/collection-parameters";
import * as collectionService from '../../../../services/data-collection.service';
import * as variableService from '../../../../services/variable-service';
import { AppEnum } from '../../../../constants/app-enum';
import { CollectionParametersState } from "../new-data-collection-state";
import _, { toInteger, toLower } from 'lodash';
import MuiDialog from "../../../../shared/mui-dialog/mui-dialog";
import VariablesPopup from "../../../connections/variables/variables-Popup";
import { toast } from "react-toastify";
import { DateParseVariable } from "../../../../constants/date-parse-variable";

const CollectionParametersController = (props) => {

    const { state, setState, setParametersState, parametersState, extractedParamters, setExtractedParamters,
         prevStepRef, setIsChangedDataParams, isTestedData , isTemplateCompany, showSaveChangesButton} = props;

    const [isAddNewParameter, setIsAddNewParameter] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const dateParser = new DateParseVariable(); 

    useEffect(() => {
        setState((prevState) => { return { ...prevState, isStepCollectionParameter: true } });
        autoDetectedParameters();
    }, [])

    const autoDetectedParameters = async () => {
        let collectionPrevParam = state.collectionParameters;
        const sourceName = state.sourceName;
        const variables = extractVariables(sourceName);

        const fieldTypeData = await getFieldTypeList();
        const fieldType = fieldTypeData.find(obj => obj.displayLabel === "Text");

        if (variables.length > 0) {
            variables.forEach((variable, index) => {
                const variableNameWithoutAt = variable.substring(1);

                if (collectionPrevParam){
                    const variableExists = collectionPrevParam.some(param => (param.parameterName === variableNameWithoutAt || param.parameterName === variable));
                    if (variableExists){
                        return;
                    }
                }

                let collectionParameter = new CollectionParametersState();
                collectionParameter.parameterName = variableNameWithoutAt;
                collectionParameter.displayName = variableNameWithoutAt
                collectionParameter.parameterTypeCD = AppEnum.ParameterTypeValue.UserInput

                collectionParameter.sysDataTypeID = fieldType?.id;
                collectionParameter.inputFieldTypeCD = fieldType?.id
                collectionParameter.sysDataTypeId =  fieldType?.id

                collectionParameter.sendAs = state.sysCollectionTypeId == 7 ?  "Query String" : "";

                let parameter = { ...collectionParameter };
                let collectionParameters = state.collectionParameters;
                collectionParameters.push(parameter);

                extractedParamters.push(parameter);
                setExtractedParamters(extractedParamters);

                setCollectionParameter(collectionParameters);
            });
        }

        if(variables.length != extractedParamters.length){
            if(extractedParamters.length > 0){

                // Extractedparameter -> Which is not in variables
                let parameterToRemove = [];

                extractedParamters.forEach((item) => {
                    if(!variables.includes(`@`+ item.parameterName)){
                        let collParameters = state.collectionParameters;
                        collParameters = collParameters.filter(i => i != item);
                        setCollectionParameter(collParameters);
                        parameterToRemove.push(item);
                    }
                })

                parameterToRemove.forEach((item) => {
                    let collParameters = extractedParamters;
                    collParameters = collParameters.filter(i => i != item);
                    setExtractedParamters(collParameters);
                })
            }
        }
    }

    function extractVariables(queryString) {
        const pattern = /@\w+/g; // Matches @ followed by one or more word characters (\w)
        const variables = [];
        let match;

        // Use a while loop to find all matches in the queryString
        while ((match = pattern.exec(queryString)) !== null) {
            variables.push(match[0]); // match[0] contains the matched variable
        }

        return variables;
    }

    // it opens the form to add a new parameters
    const addNewParameter = () => {
        setIsAddNewParameter(true);
        showSaveChangesButton(true);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
    }

    // it sets the parameter name state
    const onChangeParameterNameHandler = (event) => {

        setParametersState((prevState) => { return { ...prevState, parameterName: event.target.value } });
    }

    const onChangeParameterDisplayNameHandler = (event) => {
        setParametersState((prevState) => { return { ...prevState, displayName: event.target.value } });
    }

    // it sets the parameter name state
    const onChangeSendAsHandler = (event) => {

        setParametersState((prevState) => { return { ...prevState, sendAs: event.target.value } });
    }

    // it sets the parameter type state
    const onSelectParameterTypeHandler = (parameterTypeCD) => {        
        if(parameterTypeCD === AppEnum?.ParameterTypeValue?.UserDefinedVariable){
            setHyperText(User_Varible);
        }
        setParametersState((prevState) => { return { ...prevState, parameterTypeCD: parameterTypeCD } });
        if (parameterTypeCD === AppEnum.ParameterTypeValue.SystemDefinedVariable){
            getSystemVariableList();
        }else{
            getVariableList(parameterTypeCD);
            if (parameterTypeCD === AppEnum.ParameterTypeValue.UserInput){
                setHyperTextValue(parametersState?.inputFieldTypeCD);
                getSysAndUserDefinedVariablesList();
                getFieldTypeList();

            }

            if (parameterTypeCD === AppEnum.ParameterTypeValue.Fixed){
                setParametersState((prevState) => { return { ...prevState, isFixed: true } });
            }
            else{
                setParametersState((prevState) => { return { ...prevState, isFixed: false } });
            }
        }
    }

    // it fetches the system amd userdefined Varibles in one list

    const getSysAndUserDefinedVariablesList = () =>{

        variableService.getSysAndUserDefinedVariablesList()
            .then((response) => {

                if (response?.data)
                    setState((prevState) => { return { ...prevState, variableList: filterData(response?.data) } });
            });
    }

    // it fetches the variable list
    const getVariableList = (parameterTypeCD) => {

        collectionService.getVariableList(parameterTypeCD === AppEnum?.ParameterTypeValue?.UserDefinedVariable)
            .then((response) => {

                if (response?.data){
                    setState((prevState) => { return { ...prevState, variableList: filterData(response?.data ,parameterTypeCD) } });
                    if(variableDataType && variableDataType === User_Varible){
                        onSelectVariableHandler(completedStatus);
                    }
                }                
            })
    }

    const filterData = (rows,parameterTypeCD) =>{
        let sortedRow = rows.sort((a, b) => a.name.localeCompare(b.name));
        
        sortedRow = isTemplateCompany ? sortedRow : sortedRow?.filter(item => item?.isTemplateVariable === false && item?.templateConnectionId === null);
        return sortedRow;
    }

    const getSystemVariableList = () => {

        collectionService.getSystemDefineVariableList()
            .then((response) => {
                if (response?.data)
                    setState((prevState) => { return { ...prevState, variableList: response.data } });         
            })
    }

    // // it fetches the field type list
    // const getFieldTypeList = async () => {

    //     await collectionService.getFieldTypeList()
    //         .then((response) => {

    //             if (response?.data){
    //                 setState((prevState) => { return { ...prevState, fieldTypeList: response.data } });
    //                 return response?.data;
    //             }
    //         })
    // }

    const getFieldTypeList = async () => {
        let isCollection = true;
        const response = await collectionService.getFieldTypeList(undefined, isCollection);
        if (response?.data) {
            setState((prevState) => { return { ...prevState, fieldTypeList: response.data } });
            return response.data;
        }
    };


    // it sets the parameter variable value to the state when user types anything
    const onChangeVariableHandler = (variableName) => {
        setParametersState((prevState) => {
            return { ...prevState, defaultValue: variableName, variableUId: null }
        });
    }


    // it sets the parameter variable value to the state when user selects any option
    const onSelectVariableHandler = (variable) => {

        if (variable) {
            setParametersState((prevState) => {
                return {
                    ...prevState, defaultValue: variable?.label, variableUId: variable?.uId, isSystemDefineVariable: variable?.isSysVariable
                }
            });
            if (!parametersState.displayName) {
                setParametersState((prevState) => {
                    return { ...prevState, displayName: variable.label }
                });
            }
            if (parametersState.parameterTypeCD !== AppEnum.ParameterTypeValue.UserInput)
                setParametersState((prevState) => { return { ...prevState, sysDataTypeId: variable.sysDataTypeId, } });
        }
        else{
            const defaultValueWithoutAt = parametersState?.defaultValue.replace('@', '');
            setParametersState((prevState) => { return { ...prevState, defaultValue: '' } });
            if (parametersState.displayName == defaultValueWithoutAt) {
                setParametersState((prevState) => {
                    return { ...prevState, displayName: '' }
                });
            }
        }

        if(parametersState.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput){
            let defaultValue = "@" + variable?.label;
            if((defaultValue ?? false) && defaultValue[0] === '@'){
                defaultValue = defaultValue.replace('@', '');
                let variableObject = state.variableList.filter((obj) => obj.name === defaultValue);
                if(variableObject.length > 0){
                    //it is not possible the a variable does not have data type id whether it is system defined or udv 

                    // if(variableObject[0]?.uId !== null){
                    //     setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: variableObject[0].sysDataTypeId, sysDataTypeId: variableObject[0].sysDataTypeId, } });
                    // }else{
                    //     setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: 8, sysDataTypeId: 8, } });
                    // }

                    setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: variableObject[0].sysDataTypeId, sysDataTypeId: variableObject[0].sysDataTypeId, } });
                }
            }
        }
    }
    const [hyperText , setHyperText] = useState("");
    const [isVariableDialogOpen , setIsVariableDialogOpen] = useState(false);
    const [variableDataType , setVariableDataType] = useState();    
    const [completedStatus , setCompletedStatus] = useState();


    const Dynamic_List = "Dynamic List";
    const User_List = "User Defined List";
    const User_Varible = "User Defined Variable";
    const Number = "Number";
    const Text = "Text";
    const Boolean = "True or False";
    const Date = "Date";

    // it sets the field type and gets the variables according to its Id
    const onChangeFieldTypeHandler = (event) => {
        let id = event.target.value;

        setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: id, sysDataTypeId: id, } });
        getSysDataTypeList(id);

        setHyperTextValue(id);       
        if (id === AppEnum.DataTypeId.List){
            setParametersState((prevState) => { return { ...prevState, fixedListVariableId: id } });
        }
    } 

    const setHyperTextValue = (id)=>{   
        const dataTypeFunctions = {
            [AppEnum.DataTypeId.Number]: Number,
            [AppEnum.DataTypeId.Boolean]: Boolean,
            [AppEnum.DataTypeId.Date]: Date,
            [AppEnum.DataTypeId.String]: Text,
            [AppEnum.DataTypeId.List]: User_List
        };
        
        const selectedFunction = dataTypeFunctions[id] || null;
        setHyperText(selectedFunction);
    }

    const onShowVariablePop =(text) =>{
        setIsVariableDialogOpen(true);
        setVariableDataType(text);        
    }

    const handleCloseDialog =() =>{
        setIsVariableDialogOpen(false);
        setVariableDataType(null);
    }

    useEffect(()=>{
        if(completedStatus?.sysDataTypeId){
            if(variableDataType === User_Varible){
                getVariableList(AppEnum.ParameterTypeValue.UserDefinedVariable);
            }
            else {
                getSysDataTypeList(completedStatus?.sysDataTypeId);
            }
            handleCloseDialog();
        }     
    },[completedStatus,setCompletedStatus])

    // it fetches sys data type list by id
    const getSysDataTypeList = (sysDataTypeId) => {

        collectionService.getSysVariableList(null, sysDataTypeId)
            .then((response) => {

                if (response?.data){
                    setState((prevState) => { return { ...prevState, variableList: response.data } });
                    if(variableDataType && variableDataType !== ""){
                        onSelectVariableHandler(completedStatus);
                    }
                }
            })
    }

    // it fetches the dynamic list dropdown
    const getDynamicList = async () => {

        const response = await collectionService.getDynamicListCollection()
        if (response?.data) {
            setState((prevState) => { return { ...prevState, dynamicList: response.data } });
        }
    }

    // it sets the user defined variable value to the state
    const onChangeUserDefinedVariableHandler = (variable) => {

        if (variable) {
            setParametersState((prevState) => {
                return { ...prevState, defaultValue: variable.name, variableUId: variable.uId, parseAtClientSide: variable.parseAtClientSide, parameterValue: variable.parseAtClientSide ? dateParser.parseVariable(variable.name) : "", isSystemDefineVariable: variable.isSysVariable }
            });
            if (parametersState.parameterTypeCD !== AppEnum.ParameterType.UserInput)
                setParametersState((prevState) => { return { ...prevState, sysDataTypeId: variable.sysDataTypeId } });
            else
                setParametersState((prevState) => { return { ...prevState, sysDataTypeId: AppEnum.DataTypeId.String } });
        }
        else
            setParametersState((prevState) => { return { ...prevState, defaultValue: '', displayName: '' } });
    }

    // it sets the dynamic list value to the state
    const onChangeDynamicListHandler = (event) => {

        setParametersState((prevState) => { return { ...prevState, dynamicListCollectionID: event.target.value } });
        getColumnDetails(event.target.value, null)
    }

    const getColumnDetails = (collectionId, collectionParameter) => {

        collectionService.getColumnDetails(collectionId)

            .then((response) => {

                if (response?.data)
                    setState((prevState) => { return { ...prevState, columnList: response.data } });
                if (collectionParameter) {
                    setParametersState((prevState) => { return { ...prevState, listColumnLabel: collectionParameter.listColumnLabel, listColumnValue: collectionParameter.listColumnValue } });
                }
            })
    }

    // it sets the display column value to the state
    const onChangeDisplayColumnHandler = (event) => {
        setParametersState((prevState) => { return { ...prevState, listColumnLabel: event.target.value } });
    }

    // it sets the valule column value to the state
    const onChangeValueColumnHandler = (event) => {
        setParametersState((prevState) => { return { ...prevState, listColumnValue: event.target.value } });
    }

    // it sets the collection parameter array state
    const setCollectionParameter = (collectionParameters) => {
        setState((prevState) => { return { ...prevState, collectionParameters: collectionParameters } });
    }

    // deletes the parameter from state
    const deleteParameter = (index) => {
        setDeleteIndex(index);
        setState((prevState) => {
            return {
                ...prevState, isShowDeletePopUp: true
            }
        });

        if (state.isShowDeletePopUp) {
            let collectionParameters = state.collectionParameters;
            collectionParameters.splice(deleteIndex, 1);
            
            setCollectionParameter(collectionParameters);

            closeDialog();
            showSaveChangesButton(true);
            if(isTestedData){
                setIsChangedDataParams(true);
            }
        }
    };

    // opens form to edit the parameter
    const editParameter = async (index) => {

        let collectionParameters = state.collectionParameters;
        let selectedParametersState = collectionParameters[index];

        setHyperTextValue(toInteger(selectedParametersState?.inputFieldTypeCD));
        if (selectedParametersState.inputFieldTypeCD == AppEnum.DataTypeId.DynamicList) {
            await getDynamicList();
            setParametersState((prevState) => { return { ...prevState, dynamicListCollectionID: selectedParametersState.dynamicListCollectionID } });
            getColumnDetails(selectedParametersState.dynamicListCollectionID, selectedParametersState);
        }
        setParametersState(() => { return { ...selectedParametersState, } });

        collectionParameters[index].edit = true;
        setCollectionParameter(collectionParameters);

        setIsEditMode(true);
        showSaveChangesButton(true);
        if(isTestedData){
            setIsChangedDataParams(true);
        }
        if (selectedParametersState.parameterTypeCD === AppEnum.ParameterType.UserInput){
            getSysDataTypeList(selectedParametersState.sysDataTypeId);
            getFieldTypeList();
            getSysAndUserDefinedVariablesList();
            if((selectedParametersState.defaultValue ?? false) && selectedParametersState.defaultValue[0] === '@'){
                let defaultValue = selectedParametersState.defaultValue.replace('@', '');
                let variableObject = state.variableList.filter((obj) => obj.name === defaultValue);
                if(variableObject.length > 0){
                    if(variableObject[0]?.uId !== null){
                        setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: variableObject[0].sysDataTypeId, sysDataTypeId: variableObject[0].sysDataTypeId, } });
                    }else{
                        setParametersState((prevState) => { return { ...prevState, inputFieldTypeCD: 6, sysDataTypeId: 6, } });
                    }
                }
            }
        }
        else if(selectedParametersState.parameterTypeCD === AppEnum.ParameterTypeValue.SystemDefinedVariable){
            getSystemVariableList();
        }
        else{
            getVariableList(selectedParametersState.parameterTypeCD);
        }
    };

        // it closes the popup 
        const closeDialog = () => {
            setState((prevState) => {
                return {
                    ...prevState, isShowDeletePopUp: false
                }
            });
        }

        const onCheckOptionalParameter = (event) => {
            setParametersState((prevState) => { return { ...prevState, isOptionalParameter: event.target.checked } });
        }

        const onCheckIsMultiselectParameter = (event) => {
            setParametersState((prevState) => { return { ...prevState, isMultiSelectList: event.target.checked } });
        }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination){
            return;
        }
        const updatedRows = Array.from(state?.collectionParameters);
        const [movedRow] = updatedRows?.splice(source.index, 1);
        updatedRows.splice(destination?.index, 0, movedRow);
    
        setState((prevState) => {
            return {
                ...prevState, collectionParameters: updatedRows
            }
        });
    }; 

    return (
        <Fragment>
            <MuiDialog
                isShowPopUp={state.isShowDeletePopUp}
                secondaryButtonAction={closeDialog}
                primaryButtonAction={deleteParameter}
                closeDialog={closeDialog}
                secondaryButtonTitle="No"
                primaryButtonTitle="Yes"
                titleDescription="Are you sure you want to delete the parameter?"
            />
            <CollectionParameters
                prevStepRef={prevStepRef}
                onChangeParameterNameHandler={onChangeParameterNameHandler}
                onSelectParameterTypeHandler={onSelectParameterTypeHandler}
                onChangeSendAsHandler={onChangeSendAsHandler}
                onChangeVariableHandler={onChangeVariableHandler}
                onChangeFieldTypeHandler={onChangeFieldTypeHandler}
                onChangeUserDefinedVariableHandler={onChangeUserDefinedVariableHandler}
                onChangeDynamicListHandler={onChangeDynamicListHandler}
                onChangeDisplayColumnHandler={onChangeDisplayColumnHandler}
                onChangeValueColumnHandler={onChangeValueColumnHandler}
                onSelectVariableHandler={onSelectVariableHandler}
                addNewParameter={addNewParameter}
                setCollectionParameter={setCollectionParameter}
                editParameter={editParameter}
                deleteParameter={deleteParameter}
                parametersState={parametersState}
                setParametersState={setParametersState}
                isAddNewParameter={isAddNewParameter}
                setIsAddNewParameter={setIsAddNewParameter}
                isEditMode={isEditMode}
                hyperText={hyperText}
                onShowVariablePop={onShowVariablePop}
                setIsEditMode={setIsEditMode}
                state={state}
                setState={setState}
                onChangeParameterDisplayNameHandler={onChangeParameterDisplayNameHandler}
                onCheckOptionalParameter={onCheckOptionalParameter}
                onCheckIsMultiselectParameter={onCheckIsMultiselectParameter}
                closeDialog={closeDialog}
                isTemplateCompany={isTemplateCompany}
                onDragEnd={onDragEnd}
            />
            <VariablesPopup
                open={isVariableDialogOpen}
                handleCloseDialog ={handleCloseDialog}
                variableDataType={variableDataType}
                setCompletedStatus={setCompletedStatus}
                sysDataTypeId = {parametersState?.sysDataTypeId}
            />
        </Fragment>
    );
}

export default CollectionParametersController;

export const CollectionParametersForm = (props) => {

    const { state, fieldTypeList, setIsEditMode, setParametersState, index,
        parametersState, setIsAddNewParameter, setCollectionParameter, hyperText, onShowVariablePop,  onCheckIsMultiselectParameter, isTemplateCompany} = props;

    // it cancels the process of adding the parameter
    const cancelAddingParameter = () => {

        if (index !== null) {
            let collectionParameters = state.collectionParameters;
            collectionParameters = _.map(collectionParameters, parameter => _.omit(parameter, ['edit']));

            setCollectionParameter(collectionParameters);
        }
        setIsEditMode(false);
        setIsAddNewParameter(false);
        resetParameterState();
    }

    // it adds a new parameter
    const addParameter = () => {
        let rowIndex = index;
        const duplicateParameter = state.collectionParameters?.find((row,index) =>
            toLower(row?.parameterName) === toLower(parametersState?.parameterName?.trim()) && rowIndex !== index);

        if (duplicateParameter) {
            toast.error(`Duplicate parameter name: '${parametersState?.parameterName}'. Please ensure each parameter name is unique.`);
            return;
        }

        if (index !== null)
            updateParameter(index);
        else {
            if (parametersState.parameterName == "" && parametersState.displayName != "") {
                parametersState.parameterName = parametersState.displayName
            }
            if (parametersState.parameterName != "" && parametersState.displayName == "") {
                parametersState.displayName = parametersState.parameterName
            }
            let parameter = { ...parametersState };
            parameter.sysDataTypeID = parametersState.sysDataTypeId;
            let collectionParameters = state.collectionParameters;
            collectionParameters.push(parameter);

            setCollectionParameter(collectionParameters);
            resetParameterState();
            setIsAddNewParameter(false);
        }
    }

    // updates the selected parameter details
    const updateParameter = (index) => {

        let collectionParameters = state.collectionParameters;
        collectionParameters = _.map(collectionParameters, parameter => _.omit(parameter, ['edit']));

        collectionParameters[index] = { ...parametersState, sysDataTypeID: parametersState.sysDataTypeId };

        setIsEditMode(false);
        setIsAddNewParameter(false);
        setCollectionParameter(collectionParameters);
        resetParameterState();
    };

    // it resets the parameter state to its initial state
    const resetParameterState = () => {
        let parametersState = new CollectionParametersState();

        setParametersState(() => { return { ...parametersState, } });
    }

    // checking if basic parameters are filled
    let areBasicParamtersFilled = (parametersState.parameterName && parametersState.parameterTypeCD);

    // if required fields are filled then is enables the add button
    let isSaveEnable = (parametersState.parameterTypeCD === AppEnum.ParameterType.UserInput ?
        (parametersState.inputFieldTypeCD === AppEnum.DataTypeId.DynamicList

            ? (parametersState.inputFieldTypeCD && areBasicParamtersFilled )
                // &&
                // parametersState.listColumnValue && parametersState.listColumnLabel && parametersState.dynamicListCollectionID)

            : parametersState.inputFieldTypeCD === AppEnum.DataTypeId.List ?
                (areBasicParamtersFilled && parametersState.fixedListVariableId)

                : (areBasicParamtersFilled && parametersState.inputFieldTypeCD))

        : areBasicParamtersFilled)

    isSaveEnable = ((state.sysCollectionTypeId == 7 && isSaveEnable) ? parametersState.sendAs : isSaveEnable);

    return (
        <CollectionParametersFormView
            onChangeParameterNameHandler={props.onChangeParameterNameHandler}
            onSelectParameterTypeHandler={props.onSelectParameterTypeHandler}
            onChangeSendAsHandler={props.onChangeSendAsHandler}
            onChangeVariableHandler={props.onChangeVariableHandler}
            onChangeFieldTypeHandler={props.onChangeFieldTypeHandler}
            onChangeUserDefinedVariableHandler={props.onChangeUserDefinedVariableHandler}
            onChangeDynamicListHandler={props.onChangeDynamicListHandler}
            onChangeDisplayColumnHandler={props.onChangeDisplayColumnHandler}
            onChangeValueColumnHandler={props.onChangeValueColumnHandler}
            onSelectVariableHandler={props.onSelectVariableHandler}
            addParameter={addParameter}
            cancelAddingParameter={cancelAddingParameter}
            fieldTypeList={fieldTypeList}
            parametersState={parametersState}
            state={state}
            isTemplateCompany={isTemplateCompany}
            hyperText={hyperText}
            onShowVariablePop={onShowVariablePop}
            parameterIndex={index}
            isSaveEnable={isSaveEnable}
            onChangeParameterDisplayNameHandler={props.onChangeParameterDisplayNameHandler}
            setParametersState={props.setParametersState}
            onCheckOptionalParameter={props.onCheckOptionalParameter}
            onCheckIsMultiselectParameter={onCheckIsMultiselectParameter}
        />
    );
};

