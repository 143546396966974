import React from "react";
import "./CalculateCheckoutView.scss";
import "./UpdateCheckoutView.scss";
import { Button } from "@mui/material";
import CustomDropDown from "../customDropDown/CustomDropDown";

function UpdateCheckoutView({ featureData, developerPrice, userList, hoursList, user,
    setUser,
    hour,
    setHour, totalPrice, isDeveloperSetUp, setDeveloperSetUp ,onBuyPlan}) {


    return (
        <div className="checkoutDiv">
    
            <div className="margin-left-view"><div className="heading">Great ! We appreciate your decision to book an Expert </div>
                <div className="sub-heading">
                    Our Development team does the magic and you are at right track
                </div> </div>

            <div className="plan-discription">
                <div className="description-box">
                    <div className="plan-heading">
                        <div className="plan-type">Plan Type</div>
                        <div className="units">Units</div>
                        <div className="amount">Amount </div>
                    </div>

                    {/* it id for plan description */}
                    <div className="plan-discription-text">
                        <div>
                            <div className="margin-view">
                                <div className="small-business">
                                    Ask a consultant to Setup for you
                                </div>

                                <div className="normal-text">
                                    Excellent you have added setup support. After your order is placed , Our executive
                                </div>
                                <div className="normal-text">
                                     will send you a calendar and you can
                                </div>
                                <div className="normal-text">choose the best time that suits you. </div>
                            </div>
                            {/* {
                                isDeveloperSetUp ? <Button variant="contained" className="cancelButton" onClick={() => setDeveloperSetUp(false)}>
                                    Cancel
                                </Button> : <Button variant="contained" className="setUpButton" onClick={() => setDeveloperSetUp(true)}>
                                    YES, I need SOMEONE to setup
                                </Button>
                            } */}

                        </div>

                        <div className="units-value">
                            <div> <CustomDropDown dropDownList={hoursList} selectedValue={hour} setValue={setHour} /></div>
                            {/* <div className="small-value">
                                {
                                    isDeveloperSetUp && <CustomDropDown dropDownList={hoursList} selectedValue={hour} setValue={setHour} />
                                }
                            </div> */}
                        </div>
                        <div className="consultant-amount">
                        <div>USD <span>{developerPrice > 0 ? developerPrice * hour : 0}</span></div>
                        </div>
                    </div>
                    <div className="totalSummary-view">
                        <div>
                            <div className="total-view">
                                {" "}
                                <div className="total-text">TOTAL</div> <div className="font-value">USD <span className="total-text-value">{totalPrice > 0 ? totalPrice : 0}</span></div>{" "}
                            </div>
                            <Button variant="contained" className="standard-primary-unrounded-button" onClick={()=>onBuyPlan()}>
                                proceed to make payment
                            </Button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default UpdateCheckoutView;
