export const ASSettings = ({ flag, className }) => {
    return (
        <>
            <svg
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width="24.323"
                height="25"
                viewBox="0 0 24.323 25"
            >
                  <path id="cog-outline" d="M14.432,9.5a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0,2.5a2.5,2.5,0,1,0,2.5,2.5,2.5,2.5,0,0,0-2.5-2.5m-2.5,15a.632.632,0,0,1-.625-.525l-.462-3.312a8.682,8.682,0,0,1-2.113-1.237L5.62,23.188a.628.628,0,0,1-.762-.275l-2.5-4.325a.616.616,0,0,1,.15-.8l2.638-2.075L5.057,14.5l.088-1.25L2.507,11.212a.616.616,0,0,1-.15-.8l2.5-4.325a.614.614,0,0,1,.762-.275l3.113,1.25a9.061,9.061,0,0,1,2.113-1.225l.462-3.312A.632.632,0,0,1,11.932,2h5a.632.632,0,0,1,.625.525l.462,3.312a9.061,9.061,0,0,1,2.112,1.225l3.113-1.25a.614.614,0,0,1,.763.275l2.5,4.325a.616.616,0,0,1-.15.8L23.72,13.25l.087,1.25-.087,1.25,2.638,2.038a.616.616,0,0,1,.15.8l-2.5,4.325a.614.614,0,0,1-.763.275l-3.113-1.25a9.061,9.061,0,0,1-2.112,1.225l-.462,3.313a.632.632,0,0,1-.625.525h-5M13.495,4.5l-.462,3.262A6.865,6.865,0,0,0,9.245,9.988l-3.012-1.3-.937,1.625L7.932,12.25a6.937,6.937,0,0,0,0,4.5L5.282,18.7l.938,1.625,3.037-1.3a6.805,6.805,0,0,0,3.762,2.2l.462,3.275h1.9l.462-3.263a6.9,6.9,0,0,0,3.762-2.212l3.038,1.3.938-1.625-2.65-1.937a6.975,6.975,0,0,0,0-4.513l2.637-1.937-.937-1.625-3.012,1.3a6.775,6.775,0,0,0-3.788-2.213L15.37,4.5Z" transform="translate(-2.271 -2)" 
                  fill={flag ? "#FFF" : "#16365F"} />
            </svg>
        </>
    );
};
