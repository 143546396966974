import _ from 'lodash';

export const generateRandomString = (length) => {
    var charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.substring(randomIndex, randomIndex + 1);
    }
    return result;
}

async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return hash;
}

function base64URLEncode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export const generateCode = async (codeVerifierString) => {
    const verifier = codeVerifierString && codeVerifierString != "" ? codeVerifierString : generateRandomString(128);
    const hashedVerifier = await sha256(verifier);
    const base64EncodedHash = base64URLEncode(hashedVerifier);
    var data = {
        verifier : verifier,
        base64EncodedHash : base64EncodedHash
    }
    return data;
};

export function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
      return str;
  }
  return str.slice(0, maxLength - 3) + '...';
}

export function sortMethod(list, value) {
  return _.orderBy(list, [item => {
    const val = _.get(item, value);

    if (_.isNumber(val)) {
      return val;
    }

    return _.toString(val);
  }], ['asc']);  // 'asc' for ascending, 'desc' for descending
}
